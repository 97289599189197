import React, { useState, useEffect } from "react";
import Axios from "axios";
import { api, img_url, header_token } from "../../config";
import Select from "react-select";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";

export const SendingOnlineRider = (props) => {
  const [img, setimg] = useState("");
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  // const [total_count, settotal_count] = useState(0);
  const [limit] = useState(10);

  const [filter_name, setfilter_name] = useState("");
  const [filter_area, setfilter_area] = useState({
    value: "",
    label: "ทั้งหมด",
  });
  const [filter_type, setfilter_type] = useState({
    value: "",
    label: "ทั้งหมด",
  });

  const [option_area, setoption_area] = useState([]);
  const [option_employee_type, setoption_employee_type] = useState([]);

  const getOptionEmployee = async () => {
    try {
      let { data } = await Axios.get(`${api}/employee-type`);
      // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_employee_type(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_area(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getData = async () => {
    try {
      let send_data = {
        area_id: filter_area.value,
        employee_type_id: filter_type.value,
        full_name: filter_name,
        status: [1],
        // team_id: filter_team.value,
        role: "rider",
        available: "online",
      };

      let { data } = await Axios.post(
        `${api}/branch/user/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      let cal_page_count = Math.ceil(data.meta.total_count / limit);
      setpage_count(cal_page_count);
      // settotal_count(data.meta.total_count);

      setdata(data.users);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getData();
    getOptionArea();
    getOptionEmployee();
  }, []);

  useEffect(() => {
    getData();
  }, [filter_type, filter_name, filter_area, current_page]);

  return (
    <div>
      <div className="row">
        <form className="ml-auto">
          <div className="form-row align-items-center mb-2 mr-2 ">
            <div className=" mr-2" style={{ width: 200 }}>
              <span className="tx-13 text-muted">ค้นหา</span>
              <input
                type="text"
                className="form-control- global_filter"
                id="global_filter"
                placeholder="ชื่อ"
                value={filter_name}
                onChange={(e) => {
                  setfilter_name(e.target.value);
                }}
              />
            </div>

            <div className="ml-2 mr-3">
              <div className="row">
                <div style={{ width: 150 }} className="">
                  <span className="tx-13 text-muted">พื้นที่</span>
                  <Select
                    value={filter_area}
                    onChange={(e) => {
                      setfilter_area(e);
                    }}
                    options={option_area}
                    placeholder={"พื้นที่.."}
                  />
                </div>
              </div>
            </div>
            <div className="ml-2 mr-3">
              <div className="row">
                <div style={{ width: 150 }} className="">
                  <span className="tx-13 text-muted">ประเภท</span>
                  <Select
                    value={filter_type}
                    onChange={(e) => {
                      setfilter_type(e);
                    }}
                    options={option_employee_type}
                    placeholder={"ประเภท.."}
                  />
                </div>
              </div>
            </div>

            {/* <div className="col ml-1 mr-3">
              <div className="row">
                <div style={{ width: 120 }} className="">
                  <span className="tx-13 text-muted">ดำเนินการ</span>
                  <select className="form-control- ">
                    <option value="">เลือก</option>
                    <option value="">ลบ</option>
                    <option value="">ใช้งาน</option>
                    <option value="">ระงับใช้งาน</option>
                    <option value="">Export</option>
                  </select>
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </div>
      <div className="table-responsive">
        <table id="datatable" className="table " style={{ width: "100%" }}>
          <thead>
            <tr>
              {/* <th style={{ minWidth: 20 }}></th> */}
              <th style={{ minWidth: 30 }}>รูป</th>
              <th style={{ minWidth: 50 }}>รหัส</th>
              <th style={{ minWidth: 200 }}>ชื่อ</th>
              <th style={{ minWidth: 200 }}>ประเภท</th>
              <th style={{ minWidth: 200 }}>ทีมงาน</th>
              <th style={{ minWidth: 80 }}>สถานะ</th>
              <th style={{ minWidth: 150 }}>เบอร์โทร</th>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => {
              return (
                <tr key={index}>
                  {/* <td></td> */}
                  <td>
                    <div
                      className="thumbnail cursor-img"
                      data-toggle="modal"
                      data-target=".show-img"
                      onClick={() => {
                        setimg(
                          value.picture === ""
                            ? "/images/no-img.jpg"
                            : `${img_url}${value.picture}`
                        );
                      }}
                    >
                      <img
                        className="lazy img-responsive"
                        src={
                          value.picture === ""
                            ? "/images/no-img.jpg"
                            : `${img_url}${value.picture}`
                        }
                      />
                    </div>
                  </td>
                  <td>{value.user_id}</td>
                  <td>
                    <a
                      tabIndex={0}
                      onClick={() => {
                        props.pushToLink(`/rider/history/${value.id}`);
                      }}
                    >
                      <span>{value.full_name}</span>
                    </a>
                  </td>
                  <td>{value.employee_type.name_th}</td>
                  <td>{value.team.name_th}</td>
                  <td>
                    <span className="badge badge-success badge-pill">
                      Stand by
                    </span>
                  </td>
                  <td>{value.phone_number}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-2" style={{ float: "right" }}>
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={<span className="gap">...</span>}
          pageCount={page_count}
          onPageChange={(e) => setcurrent_page(e.selected + 1)}
          forcePage={current_page - 1}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
        />
      </div>
      <br />
      <br />

      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

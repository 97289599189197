import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import { RestaurantPromotionAdd } from "./RestaurantPromotionAdd";
import { RestaurantPromotionHistory } from "./RestaurantPromotionHistory";
import { RestaurantPromotionIndex } from "./RestaurantPromotionIndex";

export const RestaurantPromotionHead = () => {
  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">
              ร้านค้าที่ร่วมโปรโมชั่นค่าส่ง
            </h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                โปรโมชั่นค่าส่ง
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link"}
                activeClassName="active"
                to="/restaurant_promotion"
              >
                กำลังใช้งาน
              </NavLink>
            </li>

            <li className="nav-item">
            <NavLink
                exact
                className={"nav-link"}
                activeClassName="active"
                to="/restaurant_promotion/history"
              >
                ประวัติ
              </NavLink>
            </li>
            <li className="nav-item">
            <NavLink
                exact
                className={"nav-link"}
                activeClassName="active"
                to="/restaurant_promotion/add"
              >
                + เพิ่มร้านค้า
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route exact path={`/restaurant_promotion`}>
          <RestaurantPromotionIndex />
        </Route>
        <Route exact path={`/restaurant_promotion/history`}>
          <RestaurantPromotionHistory />
        </Route>
        <Route exact path={`/restaurant_promotion/add`}>
          <RestaurantPromotionAdd />
        </Route>
      </Switch>
    </div>
  );
};

import React, { useState, useEffect, useContext } from "react";
import {
  Switch,
  BrowserRouter as Route,
  NavLink,
  useHistory,
} from "react-router-dom";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/th";

import { api, header_token } from "../../config";

import swal from "sweetalert";
import moment from "moment";

import { SendingNewOrder } from "./SendingNewOrder";
import { SendingAssign } from "./SendingAssign";
import { SendingSending } from "./SendingSending";
import { SendingFinish } from "./SendingFinish";
import { SendingOnlineRider } from "./SendingOnlineRider";
import { NotiContext } from "../../store/keep";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const SendingIndex = () => {
  const { noti } = useContext(NotiContext);

  const { push } = useHistory();
  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);
  const [current_page] = useState(1);
  // const [page_count, setpage_count] = useState(0);
  const [limit] = useState(9999999);
  // const [total_count, settotal_count] = useState(0);

  const [new_count, setnew_count] = useState(0);
  const [sending_count, setsending_count] = useState(0);
  const [assign_count, setassign_count] = useState(0);
  const [success_count, setsuccess_count] = useState(0);
  const [online_count, setonline_count] = useState(0);

  const [start_date, setstart_date] = useState(moment().format("DD/MM/YYYY"));

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        end_date: start,
        order_no: "",
        start_date: start,
        // status: [],
      };

      // console.log("send", send_data);
      let { data } = await Axios.post(
        `${api}/branch/order/filter?page=${current_page}&limit=${limit}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      if (data.orders !== null) {
        // let cal_page_count = Math.ceil(data.meta.total_count / limit);
        // setpage_count(cal_page_count);
        // settotal_count(data.meta.total_count);
        setnew_count(
          data.orders.filter((e) => e.status === 1 && e.rider === null).length
        );
        setassign_count(
          data.orders.filter((e) => e.status === 1 && e.rider).length
        );
        setsending_count(data.orders.filter((e) => e.status === 4).length);
        setsuccess_count(data.orders.filter((e) => e.status === 5).length);
        // setcancel_count(
        //   data.orders.filter((e) => e.status === 0).length +
        //     data.orders.filter((e) => e.status === 6).length
        // );
        // setpreorder_count(data.orders.filter((e) => e.type === 0).length);
        setdata(data.orders);
      } else {
        setdata([]);
        setnew_count(0);
        setassign_count(0);
        setsending_count(0);
        setsuccess_count(0);
        // setcancel_count(
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const getDataOnlineRider = async () => {
    try {
      let send_data = {
        status: [1],
        // team_id: filter_team.value,
        role: "rider",
        available: "online",
      };

      let { data } = await Axios.post(
        `${api}/branch/user/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );

      setonline_count(data.meta.total_count);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const pushToLink = async (link) => {
    setTimeout(() => {
      push(link);
    }, 50);
  };

  const refresh = async (link) => {
    getData();
  };

  useEffect(() => {
    getData();
    getDataOnlineRider();
  }, []);

  useEffect(() => {
    getData();
  }, [start_date]);

  useEffect(() => {
    if (noti.getdata === "car_service"){
      getData();
    }
  }, [noti]);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-3">การจัดส่ง</h4>
            <div className="d-none d-md-flex mt-2">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าหลัก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">การจัดส่ง</p>
            </div>
          </div>

          {/* <div className="button-wrapper d-flex align-items-center mt-md-3 mt-xl-0">
            <div
              className="btn-group mt-3 mt-md-0 ml-auto"
              role="group"
              aria-label="Button group"
            >
              <button
                type="button"
                className="btn btn-outline-secondary border"
              >
                <i className="mdi mdi-reload text-body"></i>
              </button>
            </div>
          </div> */}
        </div>
      </div>

      <div className="row mb-2">
        <h4 className="  ml-3 mt-2">
          {moment(start_date, "DD/MM/YYYY").format("LL")}
        </h4>
        <div className="col-2 ml-auto">
          <div className="input-group input-daterange d-flex align-items-center">
            <DayPickerInput
              value={start_date}
              onDayChange={(e) => {
                setstart_date(e);
              }}
              overlayComponent={CustomOverlay}
              style={{ width: "100%" }}
              inputProps={{
                style: {
                  width: "100%",
                },
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />
          </div>
        </div>
      </div>
      <LoadingMask loading={loading} text={"loading..."}>
        <div className="row">
          <div className="col-12">
            <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
              <li className="nav-item">
                <NavLink
                  exact
                  className={"nav-link"}
                  activeClassName="active"
                  to="/sending"
                  style={{ height: "100%" }}
                >
                  <span className="h2 mr-2">{new_count}</span>
                  <br />
                  ไม่ได้มอบหมาย
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  className={"nav-link"}
                  activeClassName="active"
                  to="/sending/assign"
                  style={{ height: "100%" }}
                >
                  <span className="h2 mr-2">{assign_count}</span>
                  <br />
                  มอบหมายแล้ว
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  className={"nav-link"}
                  activeClassName="active"
                  to="/sending/onprocess"
                  style={{ height: "100%" }}
                >
                  <span className="h2 mr-2">{sending_count}</span>
                  <br />
                  กำลังจัดส่ง
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  className={"nav-link"}
                  activeClassName="active"
                  to="/sending/finish"
                  style={{ height: "100%" }}
                >
                  <span className="h2 mr-2">{success_count}</span>
                  <br />
                  เสร็จสิ้น
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  exact
                  className={"nav-link"}
                  activeClassName="active"
                  to="/sending/online_rider"
                  style={{ height: "100%" }}
                >
                  <span className="h2 mr-2">{online_count}</span>
                  <br />
                  พนักงานที่ออนไลน์
                </NavLink>
              </li>
            </ul>
            <div className="tab-content bg-white" id="myTabContent">
              <Switch>
                <Route exact path={`/sending`}>
                  <SendingNewOrder
                    data={data.filter(
                      (e) => e.status === 1 && e.rider === null
                    )}
                    pushToLink={pushToLink}
                    refresh={refresh}
                  />
                </Route>
                <Route exact path={`/sending/assign`}>
                  <SendingAssign
                    data={data.filter((e) => e.status === 1 && e.rider)}
                    pushToLink={pushToLink}
                    refresh={refresh}
                  />
                </Route>

                <Route exact path={`/sending/onprocess`}>
                  <SendingSending
                    data={data.filter((e) => e.status === 4)}
                    pushToLink={pushToLink}
                  />
                </Route>

                <Route exact path={`/sending/finish`}>
                  <SendingFinish
                    data={data.filter((e) => e.status === 5)}
                    pushToLink={pushToLink}
                  />
                </Route>

                <Route exact path={`/sending/online_rider`}>
                  <SendingOnlineRider pushToLink={pushToLink} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </LoadingMask>
    </div>
  );
};

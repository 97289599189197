import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import { api, header_token } from "../../config";

export const SettingFoodType = () => {
  const [loading, setloading] = useState(false);

  const [categories_data, setcategories_data] = useState([
    {
      id: "",
      title: "",
      edit: false,
      new: false,
    },
  ]);

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(`${api}/categories`);
      // console.log(data);

      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          id: value.id,
          title: value.title,
          edit: false,
          new: false,
        });
      });

      setcategories_data(tmp);
    } catch (error) {
      // console.log(error);
    }
    setloading(false);
  };

  const addCategories = async (send_data) => {
    
    try {
      let res = await Axios.post(`${api}/categories`, send_data, header_token);
      // console.log(res);
      if (res.status === 201) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(" Failed", "Please, Try again", "error");

      // console.log(error);
      return false;
    }
  };

  const editCategories = async (categories_id, send_data) => {
    
    try {
      let res = await Axios.put(
        `${api}/categories/${categories_id}`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(" Failed", "Please, Try again", "error");
      // console.log(error);
      return false;
    }
  };

  const deleteCategories = async (categories_id) => {
    
    try {
      let res = await Axios.delete(`${api}/categories/${categories_id}`, header_token);

      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(" Failed", "Please, Try again", "error");

      // console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-12">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {categories_data.length} รายการ</h4>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="content">
                        <LoadingMask loading={loading} text={"loading..."}>
                          <div style={{ minHeight: 500 }}>
                            <table id="example" className="table ">
                              <thead>
                                <tr>
                                  <th>ประเภทอาหาร</th>
                                  <th width="80">Edit</th>
                                  <th width="80">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                {categories_data.map((value, index) => {
                                  // // console.log("in loop", value);
                                  return (
                                    <tr key={value.id}>
                                      <td className="name">
                                        {value.edit === true ? (
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={value.title}
                                            onChange={(e) => {
                                              let tmp = [...categories_data];
                                              tmp[index].title = e.target.value;
                                              setcategories_data(tmp);
                                            }}
                                          />
                                        ) : (
                                          value.title
                                        )}
                                      </td>
                                      <td className="edit">
                                        {value.edit === false ? (
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                                            onClick={() => {
                                              let tmp = [...categories_data];
                                              tmp[index].edit = !tmp[index]
                                                .edit;
                                              setcategories_data(tmp);
                                            }}
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                            onClick={() => {
                                              let tmp = [...categories_data];

                                              if (tmp[index].new === true) {
                                                // // console.log(tmp[index]);
                                                addCategories({
                                                  title: tmp[index].title,
                                                });
                                              } else {
                                                // // console.log("edit");
                                                let send_data = {
                                                  title: categories_data[index].title,
                                                };
                                                // // console.log(send_data);
                                                // // console.log(value.id);
                                                editCategories(
                                                  // props.restaurantId,
                                                  value.id,
                                                  send_data
                                                );
                                              }
                                              tmp[index].edit = !tmp[index]
                                                .edit;
                                              tmp[index].new = false;

                                              setcategories_data(tmp);
                                            }}
                                          ></i>
                                        )}
                                      </td>
                                      <td className="trash">
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                                          onClick={() => {
                                            swal({
                                              title: `ต้องการลบ  ${value.title} ?`,
                                              // text:
                                              //   "Once deleted, you will not be able to recover this imaginary file!",
                                              confirmButtonText: "ลบ",
                                              cancelButtonText: "ยกเลิก",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then(async (willDelete) => {
                                              if (willDelete) {
                                                let is_delete = deleteCategories(
                                                  value.id
                                                );

                                                if (is_delete) {
                                                  swal("ลบเสร็จสิ้น !!", {
                                                    icon: "success",
                                                  });
                                                } else {
                                                  swal("เกิดข้อผิดพลาด !!", {
                                                    icon: "error",
                                                  });
                                                }
                                              }
                                            });
                                          }}
                                        ></i>
                                      </td>
                                    </tr>
                                  );
                                })}

                                <tr>
                                  <td
                                    className="new-row"
                                    colSpan="5"
                                    onClick={() => {
                                      let tmp = [...categories_data];
                                      tmp.push({
                                        id: "",
                                        title: "",
                                        edit: true,
                                        new: true,
                                      });
                                      setcategories_data(tmp);
                                    }}
                                  >
                                    <span className="h3">+</span>{" "}
                                    <span className="h6">Add</span>{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </LoadingMask>
                      </div>
                      {/* button */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

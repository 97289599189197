import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import dropify from "dropify";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import Select from "react-select";
import { useParams, Link, useHistory } from "react-router-dom";
import Axios from "axios";
import {
  api,
  menu_status_option,
  header_token,
  image_size,
} from "../../config";
import swal from "sweetalert";

export const RestaurantDetailMenuAdd = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const menu_group_ref = useRef(null);

  const [menu_group, setmenu_group] = useState([]);
  const [img_cover, setimg_cover] = useState({
    preview: "",
    raw: "",
    base64: "",
  });
  const [img_thumbnail, setimg_thumbnail] = useState({
    preview: "",
    raw: "",
    base64: "",
  });
  const [menu_name, setmenu_name] = useState("");
  const [detail, setdetail] = useState("");
  const [time, settime] = useState(0);
  const [price, setprice] = useState(0);
  const [sale, setsale] = useState(false);
  const [is_group_menu, setis_group_menu] = useState(false);
  const [sale_price, setsale_price] = useState(0);
  const [status, setstatus] = useState({ value: 1, label: "ใช้งาน" });
  const [add_on, setadd_on] = useState([]);
  const [menu_add_on, setmenu_add_on] = useState([
    {
      add_on_id: "",
      options_id: ["", ""],
      min: 0,
      max: 0,
    },
  ]);

  const [option_menu_group, setoption_menu_group] = useState([]);

  const getOptionMenuGroup = async () => {
    try {
      let { data } = await Axios.get(`${api}/restaurants/${id}/menu-group`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.title });
      });
      setoption_menu_group(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getAddOn = async () => {
    try {
      let { data } = await Axios.get(`${api}/restaurants/${id}/add-on`);
      // console.log(data);
      let tmp = [];
      data.map((value, idx) => {
        let options = [];
        value.options.map((v, i) => {
          options.push({ id: v.id, checked: false });
        });
        tmp.push({
          add_on_id: value.id,
          options_id: options,
          status: "",
          min: 0,
          max: 0,
        });
      });
      setmenu_add_on(tmp);
      setadd_on(data);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const sendData = async (send_data) => {
    try {
      let res = await Axios.post(
        `${api}/restaurants/${id}/menu`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 200 || res.status === 201) {
        // window.location = `/restaurant/detail/${id}/menu`;
        push(`/restaurant/detail/${id}/menu`);
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    $(".dropify").dropify();
    // console.log(id);
    getOptionMenuGroup();
    getAddOn();
  }, []);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">เพิ่มรายการอาหาร</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                เพิ่มรายการอาหาร
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">รูป Cover(500*500)</label>
                    </div>
                    <div className="col-lg-5" id="preview-img-div">
                      <input
                        type="file"
                        id="preview-img"
                        className="dropify"
                        data-max-file-size="1M"
                        accept=".png, .jpg, .jpeg"
                        data-default-file=""
                        onChange={async (e) => {
                          if (e.target.files.length) {
                            let img_file = e.target.files[0];
                            let base64img = await toBase64(img_file);
                            if (await image_size(base64img, 500, 500)) {
                              setimg_cover({
                                preview: URL.createObjectURL(img_file),
                                base64: base64img,
                              });
                            } else {
                              setTimeout(() => {
                                $(
                                  "#preview-img-div .dropify-wrapper .dropify-clear"
                                ).trigger("click");
                                alert("ขนาดภาพเกินกว่าที่กำหนด");
                              }, 50);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">รูป Thumbnail(150*150)</label>
                    </div>
                    <div className="col-lg-5" id="thumbnail-img-div">
                      <input
                        type="file"
                        id="thumbnail-img"
                        className="dropify"
                        data-max-file-size="1M"
                        accept=".png, .jpg, .jpeg"
                        data-default-file=""
                        onChange={async (e) => {
                          if (e.target.files.length) {
                            let img_file = e.target.files[0];
                            let base64img = await toBase64(img_file);
                            if (await image_size(base64img, 150, 150)) {
                              setimg_thumbnail({
                                preview: URL.createObjectURL(img_file),
                                base64: base64img,
                              });
                            } else {
                              setTimeout(() => {
                                $(
                                  "#thumbnail-img-div .dropify-wrapper .dropify-clear"
                                ).trigger("click");
                                alert("ขนาดภาพเกินกว่าที่กำหนด");
                              }, 50);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">ชื่อเมนู</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="name"
                        className="form-control"
                        name="name"
                        type="text"
                        value={menu_name}
                        onChange={(e) => {
                          setmenu_name(e.target.value);
                          // console.log("menu_addd_on ", menu_add_on);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">รายละเอียด</label>
                    </div>
                    <div className="col-lg-5">
                      <SimpleMDE
                        id="detail-restaurant"
                        // label="Your label"
                        onChange={(value) => {
                          // console.log(value);
                          setdetail(value);
                          //   alert(detail)

                          // console.log(detail);
                        }}
                        value={detail}
                        options={{
                          autofocus: true,
                          spellChecker: false,
                          // etc.
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="time">เวลาการปรุง(นาที)</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="time"
                        className="form-control"
                        name="time"
                        type="number"
                        value={time}
                        onChange={(e) => {
                          settime(parseInt(e.target.value));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="price">ราคา</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="price"
                        className="form-control"
                        name="price"
                        type="number"
                        value={price}
                        onChange={(e) => {
                          setprice(parseInt(e.target.value));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">สินค้านี้กำลังลดราคา</label>
                    </div>
                    <div className="col-lg-5">
                      <label className="toggle-switch toggle-switch-success">
                        <input
                          type="checkbox"
                          checked={sale}
                          onChange={() => {
                            setsale(!sale);
                          }}
                        />
                        <span className="toggle-slider round"></span>
                      </label>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="sale_price">ราคาโปรโมชั่น</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="sale_price"
                        className="form-control"
                        name="sale_price"
                        type="number"
                        value={sale_price}
                        onChange={(e) => {
                          setsale_price(parseInt(e.target.value));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">หมวดหมู่</label>
                    </div>
                    <div className="col-lg-5">
                      <Select
                        ref={menu_group_ref}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: is_group_menu ? "red" : "#cdd4e0",

                            "&:focus": {
                              borderColor: is_group_menu ? "red" : "#cdd4e0",
                            },
                          }),
                        }}
                        value={menu_group}
                        onChange={(e) => {
                          setmenu_group(e);
                          if (e.length > 0) {
                            setis_group_menu(false);
                          } else {
                            setis_group_menu(true);
                          }
                        }}
                        options={option_menu_group}
                        placeholder={"เลือกหมวดหมู่..."}
                        closeMenuOnSelect={false}
                        isMulti
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">สถานะ</label>
                    </div>
                    <div className="col-lg-5">
                      <Select
                        value={status}
                        onChange={(e) => {
                          // console.log(e);
                          setstatus(e);
                        }}
                        options={menu_status_option}
                        placeholder={"สถานะ"}
                      />
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12">
          <h5>เพิ่ม Add On</h5>
          <div className="card">
            <div className="card-body">
              {add_on.map((value, idx) => {
                return (
                  <table id="example" className="table " key={idx}>
                    <thead>
                      <tr>
                        <td
                          colSpan="2"
                          style={{
                            backgroundColor: "#f1f3f6",
                            paddingTop: ".85rem",
                            paddingBottom: ".85rem",
                          }}
                        >
                          <div className="row">
                            <div className="col-7">
                              <span className="h5 ml-2 mt-2">
                                {value.name_th}
                              </span>
                            </div>
                            <div className="col-5">
                              <div
                                style={{
                                  float: "right",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                ขั้นต่ำ:
                                <input
                                  type="number"
                                  className="form-control ml-2 mr-2"
                                  style={{ maxWidth: 65 }}
                                  value={menu_add_on[idx].min}
                                  onChange={(e) => {
                                    if (e.target.value && e.target.value > 0) {
                                      let tmp = [...menu_add_on];
                                      tmp[idx].min = parseInt(e.target.value);
                                      setmenu_add_on(tmp);
                                    } else {
                                      let tmp = [...menu_add_on];
                                      tmp[idx].min = 0;
                                      setmenu_add_on(tmp);
                                    }
                                  }}
                                />
                                ไม่เกิน:
                                <input
                                  type="number"
                                  className="form-control "
                                  style={{ maxWidth: 65 }}
                                  value={menu_add_on[idx].max}
                                  onChange={(e) => {
                                    if (e.target.value && e.target.value >= 1) {
                                      let tmp = [...menu_add_on];
                                      tmp[idx].max = parseInt(e.target.value);
                                      setmenu_add_on(tmp);
                                    } else {
                                      let tmp = [...menu_add_on];
                                      tmp[idx].max = 1;
                                      setmenu_add_on(tmp);
                                    }
                                  }}
                                />
                              </div>

                              {/* <Select
                                value={menu_add_on[idx].status}
                                onChange={(e) => {
                                  let tmp = [...menu_add_on];
                                  tmp[idx].status = e;
                                  setmenu_add_on(tmp);
                                }}
                                options={menu_option}
                              /> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {value.options.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <span className=" ml-2 ">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value={menu_add_on[idx].options_id[i].checked}
                                  onChange={() => {
                                    let tmp = [...menu_add_on];
                                    tmp[idx].options_id[i].checked = !tmp[idx]
                                      .options_id[i].checked;
                                    setmenu_add_on(tmp);
                                  }}
                                />
                                {v.name_th}
                              </span>
                            </td>
                            <td align={"right"}>{v.price}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="row">
            <Link
              to={`/restaurant/detail/${id}/menu`}
              className="btn btn-outline-primary mr-2 ml-auto mt-2 "
            >
              ยกเลิก
            </Link>

            <button
              onClick={() => {
                if (menu_group.length > 0) {
                  setis_group_menu(false);
                  let tmp_menu = [];

                  menu_add_on.forEach((value) => {
                    let options_id = [];
                    //   if (value.status) {
                    value.options_id.forEach((v) => {
                      if (v.checked === true) options_id.push(v.id);
                    });

                    if (options_id.length !== 0) {
                      tmp_menu.push({
                        add_on_id: value.add_on_id,
                        options_id: options_id,
                        //   status: value.status.value,
                        min: parseInt(value.min),
                        max: parseInt(value.max),
                      });
                    }
                    //   }
                  });

                  let tmp_menu_group = [];
                  menu_group.forEach((vl) => {
                    tmp_menu_group.push(vl.value);
                  });

                  let send_data = {
                    cook_time: time,
                    cover: img_cover.base64,
                    // cover: "",
                    description: detail,
                    is_promotion: sale,
                    menu_add_on: tmp_menu,
                    // menu_group_id: [menu_group.value],
                    menu_group_id: tmp_menu_group,
                    name_en: "",
                    name_th: menu_name.trim(),
                    price: price,
                    promotion_price: sale_price,
                    status: parseInt(status.value),
                    thumbnail: img_thumbnail.base64,
                    // thumbnail: "",
                  };
                  // console.log("send_data", send_data);

                  sendData(send_data);
                } else {
                  setis_group_menu(true);
                  menu_group_ref.current.focus();
                }
              }}
              className="btn btn-primary  mr-2  mt-2 "
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { api, header_token, img_url, recommened_options } from "../../config";
import Axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import "moment/locale/th";
import swal from "sweetalert";

export const RecommenedIndex = () => {
  const checkAllRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [loadingAll, setloadingAll] = useState(false);

  const [data, setdata] = useState([]);

  const [img, setimg] = useState("");
  const [current_page, setcurrent_page] = useState(1);
  const [total_page, settotal_page] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [restaurant_option, setrestaurant_option] = useState("");
  const [select_restaurant, setselect_restaurant] = useState("");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [status, setstatus] = useState("");
  const [recom_id, setrecom_id] = useState("");

  const [select_cancel, setselect_cancel] = useState("");
  const [filter_name, setfilter_name] = useState("");
  const [filter_status, setfilter_status] = useState("");

  const getData = async () => {
    setloadingAll(true);

    try {
      let { data } = await Axios.get(
        `${api}/branch/restaurants/recommend?page=${current_page}&limit=${limit}&status=${
          filter_status.value ? parseInt(filter_status.value) : 1
        }&keyword=${filter_name}`,
        header_token
      );
      // console.log(data);
      let page_count = Math.ceil(data.meta.total_count / limit);
      settotal_page(page_count);
      settotal_count(data.meta.total_count);

      setdata(data.recommends ? data.recommends : []);
    } catch (error) {
      console.log(error);
    }
    setloadingAll(false);
  };

  const getDataRestaurant = async () => {
    setloading(true);

    let send_data = {
      // name_th: filter_name,
      // status: [0],
    };
    try {
      let { data } = await Axios.post(
        `${api}/branch/restaurants/filter?page=${1}&limit=${limit}`,
        send_data,
        header_token
      );

      let tmp = [];
      data.restaurants.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      // console.log(tmp);
      setrestaurant_option(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const editRecommened = async (send_data) => {
    try {
      // console.log(send_data);
      let res = await Axios.put(
        `${api}/restaurants/recommend`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getData();
        swal("แก้ไขร้านแนะนำเสร็จสิ้น !!", {
          icon: "success",
        });
        setselect_restaurant("");
        setstart("");
        setend("");
        setstatus("");
        setrecom_id("");
      } else {
        swal("ผิดพลาด !!", {
          icon: "error",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const cancelRecommened = async (send_data) => {
    try {
      // console.log(send_data);
      let res = await Axios.put(
        `${api}/restaurants/recommend`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  useEffect(() => {
    getData();
    getDataRestaurant();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_status, filter_name]);

  useEffect(() => {
    getData();
  }, [current_page]);

  // useEffect(() => {
  //   getDataRestaurant();
  // }, [filter_name]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
              </div>

              <div className="form-row align-items-center mb-2">
                <div className="col mr-2">
                  <span>ค้นหา</span>
                  <input
                    type="text"
                    className="form-control- global_filter"
                    id="global_filter"
                    placeholder="ร้านอาหาร"
                    value={filter_name}
                    onChange={(e) => setfilter_name(e.target.value)}
                  />
                </div>

                <div className="col">
                  <div className="row">
                    <div style={{ width: 150 }}>
                      <span>สถานะ</span>
                      <Select
                        value={filter_status}
                        onChange={async (e) => {
                          setfilter_status(e);
                        }}
                        options={recommened_options}
                        placeholder={"สถานะ..."}
                      />
                    </div>
                  </div>
                </div>

                <div className="col ml-3">
                  <div className="row">
                    <div style={{ width: 150 }}>
                      <span>ดำเนินการ</span>
                      <Select
                        value={select_cancel}
                        onChange={(e) => {
                          setselect_cancel(e);
                        }}
                        options={[{ value: "cancel", label: "ยกเลิก" }]}
                        placeholder={"ดำเนินการ..."}
                      />
                    </div>
                  </div>
                </div>

                <div className="col ml-auto">
                  <div style={{ maxWidth: 150 }}>
                    <button
                      className="btn btn-primary  mt-4 "
                      onClick={async () => {
                        let count = data.filter((e) => e.checked === true);
                        if (
                          select_cancel.value === "cancel" &&
                          count.length > 0
                        ) {
                          swal({
                            title: `ยกเลิกร้านแนะนำที่เลือกหรือไม่ ?`,
                            confirmButtonText: "ใช่",
                            cancelButtonText: "ไม่",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              let sending = [];
                              data.forEach((value) => {
                                if (value.checked === true) {
                                  // console.log(value)
                                  let send_data = {
                                    begin: moment(value.begin).format(),
                                    end: moment(value.end).format(),
                                    id: value.id,
                                    status: 0,
                                  };

                                  sending.push(cancelRecommened(send_data));
                                }
                              });

                              let is_success = true;
                              await Promise.all(sending).then((res) => {
                                // console.log(res);
                                if (
                                  res.filter((e) => e === true).length === 0 ||
                                  res.length === 0
                                )
                                  is_success = false;
                              });

                              if (is_success) {
                                getData();

                                swal("ยกเลิกเสร็จสิ้น !!", {
                                  icon: "success",
                                });
                              } else {
                                swal("เกิดข้อผิดพลาด !!", {
                                  icon: "error",
                                });
                              }
                            }
                          });
                        } else {
                          alert("เลือกตัวดำเนินการ");
                        }
                      }}
                    >
                      ดำเนินการ
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <LoadingMask loading={loadingAll} text={"loading..."}>
              <div style={{ minHeight: 500 }}>
                <div className="table-responsive">
                  <table id="dataTable" className="table table-bordered-">
                    <thead>
                      <tr>
                        <th style={{ minWidth: 30 }}>
                          <input
                            type="checkbox"
                            className="selectAll "
                            name="selectAll"
                            defaultChecked={false}
                            ref={checkAllRef}
                            onClick={(e) => {
                              console.log(checkAllRef.current.checked);
                              let tmp = [...data];
                              let _tmp = [];
                              tmp.forEach((value, idx) => {
                                value.checked = checkAllRef.current.checked;
                                _tmp.push(value);
                              });
                              setdata(_tmp);
                              console.log(data);
                            }}
                          />
                        </th>
                        <th style={{ minWidth: 80 }}>รูป</th>
                        <th style={{ minWidth: 250 }}>ร้านอาหาร</th>
                        <th style={{ minWidth: 100 }}>เริ่มวันที่</th>
                        <th style={{ minWidth: 100 }}>ถึงวันที่</th>
                        <th style={{ minWidth: 80 }}>สถานะ</th>
                        <th style={{ minWidth: 100 }}>ดำเนินการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ minWidth: 30 }}>
                              <input
                                type="checkbox"
                                checked={value.checked || ""}
                                onChange={(e) => {
                                  let tmp = [...data];
                                  data.forEach((vl, idx) => {
                                    if (vl.id === value.id) {
                                      tmp[idx].checked = !tmp[idx].checked;
                                      setdata(tmp);
                                    }
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <div
                                className="thumbnail cursor-img"
                                data-toggle="modal"
                                data-target=".show-img"
                                onClick={() => {
                                  setimg(
                                    value.restaurant.picture === ""
                                      ? "/images/no-img.jpg"
                                      : `${img_url}${value.restaurant.picture}`
                                  );
                                }}
                              >
                                <img
                                  className="lazy img-responsive"
                                  src={
                                    value.restaurant.picture === ""
                                      ? "/images/no-img.jpg"
                                      : `${img_url}${value.restaurant.picture}`
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <Link
                                to={`/restaurant/detail/${value.restaurant.id}/history`}
                              >
                                <span>{value.restaurant.name_th}</span>
                              </Link>
                            </td>
                            <td>{moment(value.begin).format("DD/MM/YYYY")}</td>
                            <td>{moment(value.end).format("DD/MM/YYYY")}</td>
                            <td>
                              {(() => {
                                switch (value.status) {
                                  case 0:
                                    return (
                                      <div className="badge badge-warning">
                                        ยกเลิก
                                      </div>
                                    );
                                  case 1:
                                    return (
                                      <div className="badge badge-success">
                                        ใช้งาน
                                      </div>
                                    );

                                  case 2:
                                    return (
                                      <div className="badge badge-secondary">
                                        ระงับ
                                      </div>
                                    );
                                  case 3:
                                    return (
                                      <div className="badge badge-danger">
                                        หมดอายุ
                                      </div>
                                    );

                                  default:
                                    break;
                                }
                              })()}
                            </td>
                            <td>
                              <button
                                data-toggle="modal"
                                data-target=".edit-recommened"
                                className="btn btn-outline-primary btn-sm mb-1"
                                onClick={() => {
                                  // console.log(value.restaurant)
                                  setselect_restaurant({
                                    value: value.restaurant.id,
                                    label: value.restaurant.name_th,
                                  });
                                  setstart(moment(value.begin).toDate());
                                  setend(moment(value.end).toDate());
                                  setstatus(
                                    recommened_options.filter(
                                      (e) => parseInt(e.value) === parseInt(value.status)
                                    )[0]
                                  );
                                  
                                  setrecom_id(value.id);
                                }}
                              >
                                แก้ไข
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="mt-2" style={{ float: "right" }}>
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={<span className="gap">...</span>}
                    pageCount={total_page}
                    onPageChange={(e) => setcurrent_page(e.selected + 1)}
                    forcePage={current_page - 1}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previous_page"}
                    nextLinkClassName={"next_page"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </LoadingMask>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  edit-recommened" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>แก้ไขร้านค้าแนะนำ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3 pl-3">
                  <label htmlFor="name">เลือกร้านค้า</label>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-11">
                      <div className="side-by-side clearfix">
                        <LoadingMask loading={loading} text={"loading..."}>
                          <Select
                            value={select_restaurant}
                            onChange={async (e) => {
                              setselect_restaurant(e);
                            }}
                            options={restaurant_option}
                            placeholder={"ค้นหา..."}
                            onKeyDown={async (e) => {
                              let val = e.target.value;
                              setTimeout(async () => {
                                await setfilter_name(val);
                              }, 50);
                            }}
                            isClearable={true}
                            isDisabled
                          />
                        </LoadingMask>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3 pl-3">
                  <label htmlFor="name">กำหนดระยะเวลา</label>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-5">
                      <DayPickerInput
                        value={start}
                        onDayChange={(e) => {
                          setstart(e);
                          if (!end) {
                            setend(e);
                          }
                        }}
                        placeholder={"วัน/เดือน/ปี"}
                        style={{ width: "100%" }}
                        inputProps={{
                          style: {
                            width: "100%",
                          },
                        }}
                        format="L"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                          locale: "th",
                          localeUtils: MomentLocaleUtils,
                        }}
                      />
                    </div>
                    <div className="col-1 text-center">ถึง</div>
                    <div className="col-5">
                      <DayPickerInput
                        value={end}
                        onDayChange={(e) => {
                          setend(e);
                          if (!start) {
                            setstart(e);
                          }
                        }}
                        // overlayComponent={CustomOverlay}
                        placeholder={"วัน/เดือน/ปี"}
                        style={{ width: "100%" }}
                        inputProps={{
                          style: {
                            width: "100%",
                          },
                        }}
                        format="L"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                          locale: "th",
                          localeUtils: MomentLocaleUtils,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3 pl-3">
                  <label htmlFor="name">สถานะ</label>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-11">
                      <div className="side-by-side clearfix">
                        <Select
                          value={status}
                          onChange={async (e) => {
                            setstatus(e);
                          }}
                          options={recommened_options}
                          placeholder={"เลือก..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <div className="row">
                    <button
                      className="btn btn-outline-primary mr-2 ml-auto mt-2 "
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2  mt-2 "
                      onClick={() => {
                        let send_data = {
                          begin: moment(start).format(),
                          end: moment(end).format(),
                          id: recom_id,
                          status: parseInt(status.value),
                        };
                        editRecommened(send_data);
                      }}
                    >
                      แก้ไข
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useRef } from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportOrderCarData = ({ data, trigger, settrigger }) => {
  const exportButton = useRef();

  useEffect(() => {
    if (trigger) {
      exportButton.current.click();
      settrigger(false);
    }
  }, [trigger]);

  return (
    <ExcelFile
      filename={`Order_Car_export`}
      element={
        <button
          type="submit"
          className="btn btn-primary mr-2"
          style={{ marginTop: 23, display: "none" }}
          ref={exportButton}
        >
          Download Data
        </button>
      }
    >
      <ExcelSheet data={data} name="Order Car Service">
        <ExcelColumn label="Order No" value="order_no" />
        <ExcelColumn label="เวลา" value="order_time" />
        <ExcelColumn label="การจัดส่ง	" value="delivery" />
        <ExcelColumn label="บริการ" value="car_service_name" />
        <ExcelColumn label="ต้นทาง" value="address" />
        <ExcelColumn label="ปลายทาง" value="destinations" />
        <ExcelColumn label="ผู้รับงาน" value="rider" />
        <ExcelColumn label="ค่าขนส่ง" value="car_service_cost" />
        <ExcelColumn label="ค่าบริการเสริม" value="car_service_addon_cost" />
        <ExcelColumn label="รวม" value="total" />
        <ExcelColumn label="ระยะทาง" value="distance" />
        <ExcelColumn label="สถานะ" value="status" />
      </ExcelSheet>
    </ExcelFile>
  );
};

import React, { useState, useEffect } from "react";
import { RiderHead } from "./RiderHead";
import * as XLSX from "xlsx";
import { ExampleImportRider } from "./ExampleImportRider";
import Axios from "axios";
import { api } from "../../config";
import swal from "sweetalert";
import moment from "moment";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

export const RiderImport = () => {
  const [loading, setloading] = useState(false);
  const [import_error, setimport_error] = useState(false);

  const [file_name, setfile_name] = useState("");
  const [file, setfile] = useState("");

  const [tmp, settmp] = useState([]);
  const [data, setdata] = useState([]);
  const [send_data, setsend_data] = useState([]);
  const [province_options, setprovince_options] = useState([]);
  const [option_vehicle_type, setoption_vehicle_type] = useState([]);

  const getOptionSubDistrict = async (district_id, sub_district_name) => {
    try {
      let { data } = await Axios.get(
        `${api}/sub-district/district/${district_id}`
      );
      let x;
      data.forEach((value) => {
        if (sub_district_name === value.name_th) {
          x = value.id;
        }
      });

      return x;
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionDistrict = async (province_id, district_name, sub) => {
    try {
      let district = await Axios.get(`${api}/district/province/${province_id}`);
      let dis_id;
      let sub_id;
      district.data.forEach(async (value) => {
        if (district_name === value.name_th) {
          dis_id = value.id;
          return;
        }
      });

      sub_id = await getOptionSubDistrict(dis_id, sub);

      return { dis_id, sub_id };
      // setoption_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionProvince = async () => {
    try {
      let { data } = await Axios.get(`${api}/province`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setprovince_options(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionVehicleType = async () => {
    try {
      let { data } = await Axios.get(`${api}/vehicle-type`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_vehicle_type(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const maptoarr = async () => {
    // console.log(tmp);

    let is_pass = true;
    let arr = [];
    // tmp.map(async (value, index) => {
    for (let value of tmp) {
      // console.log(value["G"].trim());
      let province_id;

      province_options.forEach((e) => {
        if (e.label === value["G"].trim()) {
          province_id = e.value;
          return;
        }
      });

      let output = await getOptionDistrict(
        province_id,
        value["F"].trim(),
        value["E"].trim()
      );
      let district_id = output.dis_id;
      let sub_district_id = output.sub_id;

      let vehicle_type_id = false;
      option_vehicle_type.forEach((e) => {
        if (e.label === value["L"].trim()) {
          vehicle_type_id = e.value;
          return;
        }
      });
      // console.log(province_id, district_id, sub_district_id);
      if (
        !province_id ||
        !district_id ||
        !sub_district_id ||
        !vehicle_type_id
      ) {
        is_pass = false;
      }

      arr.push({
        about: "",
        area_id: [],
        birth_date: moment(value["C"].trim(), "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        credit: 0,
        degree_id: "",
        display_name: "",
        driving_license: "",
        driving_picture: "",
        email: value["K"].trim(),
        employee_type_id: "",
        full_name: value["A"].trim(),
        gender_id: "",
        identification_number: value["B"].trim(),
        identification_picture: "",
        license_plate: value["M"].trim(),
        line_id: value["J"].trim(),
        location: {
          address: value["D"].trim(),
          district_id: district_id,
          latitude: 0,
          longitude: 0,
          province_id: province_id,
          sub_district_id: sub_district_id,
        },
        phone_number: value["I"].trim(),
        point: 0,
        picture: "",
        provider: {
          password: value["Q"].trim(),
          provider_id: "",
          provider_name: "password",
          provider_token: "",
          username: value["P"].trim(),
        },
        register_date: moment(new Date()).format("YYYY-MM-DD"),
        status: 3, // รออนุมัติ
        team_id: "",
        thumbnail: "",
        title_id: "",
        vehicle_color: value["O"].trim(),
        vehicle_make: value["N"].trim(),
        vehicle_type_id: vehicle_type_id,
      });
      // console.log(arr);
    }
    // });

    if (is_pass) {
      setimport_error(false);
      setdata(tmp);
      setsend_data(arr);
    } else {
      setimport_error(true);
      setdata([]);
      setsend_data([]);
      setfile("");
    }
  };

  const addRider = async (send_data) => {
    try {
      let res = await Axios.post(`${api}/rider`, send_data);
      // console.log(res);
      if (res.status === 201) {
        return true;
      }
    } catch (error) {
      console.log(error.response);
      return false;
    }
  };

  useEffect(() => {
    maptoarr();
  }, [tmp]);

  useEffect(() => {
    getOptionProvince();
    getOptionVehicleType();
  }, []);

  return (
    <div>
      <RiderHead />

      <div className="row mb-4 mt-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <span className="mb-4 h5">เลือกไฟล์ที่ต้องการ</span> |{" "}
              <ExampleImportRider />
              <br />
              <div className="input-group mb-3">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    // className="form-control"
                    accept=".xlsx , .xls"
                    value={file}
                    onChange={async (e) => {
                      setfile(e.target.value);
                      setfile_name(e.target.files[0].name);

                      let f = e.target.files[0];

                      const reader = new FileReader();
                      reader.onload = (e) => {
                        // evt = on_file_select event
                        /* Parse data */
                        const bstr = e.target.result;
                        const wb = XLSX.read(bstr, { type: "binary" });
                        /* Get first worksheet */
                        const wsname = wb.SheetNames[0];
                        const ws = wb.Sheets[wsname];
                        /* Convert array of arrays */
                        const data = XLSX.utils.sheet_to_json(ws, {
                          header: "A",
                        });
                        /* Update state */
                        let _data = [...data];
                        _data.shift();
                        // console.log("Data>>>", _data);
                        settmp(_data);
                      };
                      reader.readAsBinaryString(f);
                    }}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile02"
                  >
                    {file_name ? file_name : "Choose file"}
                  </label>
                </div>
                {/* <div className="input-group-append">
                  <span className="input-group-text">Upload</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row pr-3 mb-2">
                <span className="h5 ml-3 mt-2">
                  {data.length.toLocaleString()} รายการ
                </span>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div className="table-responsive">
                  <table id="dataTable" className="table">
                    <thead>
                      <tr>
                        <th style={{ minWidth: 250 }}>ชื่อ-นามสกุล</th>
                        <th style={{ minWidth: 150 }}>เลขที่บัตรประชาชน</th>
                        <th style={{ minWidth: 150 }}>วัน/เดือน/ปีเกิด</th>
                        {/* <th style={{ minWidth: 300 }}>วุฒิการศึกษา</th> */}
                        <th style={{ minWidth: 300 }}>ที่อยู่</th>
                        <th style={{ minWidth: 120 }}>ตำบล/แขวง</th>
                        <th style={{ minWidth: 120 }}>อำเภอ/เขต</th>
                        <th style={{ minWidth: 120 }}>จังหวัด</th>
                        <th style={{ minWidth: 100 }}>รหัสไปรษณีย์</th>
                        {/* <th style={{ minWidth: 200 }}>ทีมงาน</th> */}
                        <th style={{ minWidth: 150 }}>เบอร์โทร</th>
                        <th style={{ minWidth: 100 }}>ID LIne</th>
                        <th style={{ minWidth: 100 }}>อีเมล์</th>
                        {/* <th style={{ minWidth: 220 }}>เครดิต</th>
                      <th style={{ minWidth: 100 }}>แต้ม</th> */}
                        {/* <th style={{ minWidth: 150 }}>ประเภท</th> */}
                        {/* <th style={{ minWidth: 120 }}>วันที่</th> */}
                        <th style={{ minWidth: 120 }}>พาหนะ</th>
                        <th style={{ minWidth: 100 }}>ทะเบียน</th>
                        <th style={{ minWidth: 100 }}>รุ่น</th>
                        <th style={{ minWidth: 100 }}>สี</th>
                        {/* <th style={{ minWidth: 80 }}>สถานะ</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {import_error === true ? (
                        <tr>
                          <td colSpan="15">
                            <span style={{ color: "red" }}>
                              ข้อมูลที่ import มีข้อผิดพลาด
                            </span>
                          </td>
                        </tr>
                      ) : null}
                      {data.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value["A"]}</td>
                            <td>{value["B"]}</td>
                            <td>{value["C"]}</td>
                            <td>{value["D"]}</td>
                            <td>{value["E"]}</td>
                            <td>{value["F"]}</td>
                            <td>{value["G"]}</td>
                            <td>{value["H"]}</td>
                            <td>{value["I"]}</td>
                            <td>{value["J"]}</td>
                            <td>{value["K"]}</td>
                            <td>{value["L"]}</td>
                            <td>{value["M"]}</td>
                            <td>{value["N"]}</td>
                            <td>{value["O"]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="row pr-3 mt-4">
                  <button
                    className="btn btn-primary btn-sm mr-2 ml-auto"
                    onClick={() => {
                      if (send_data.length > 0) {
                        swal({
                          title: `ต้องการเพิ่มพนักงานขับรถทั้งหมดใช่หรือไม่ ?`,
                          confirmButtonText: "ใช่",
                          cancelButtonText: "ยกเลิก",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let sending = [];
                            send_data.forEach((value) => {
                              sending.push(addRider(value));
                            });

                            // console.log("sending", sending);

                            let is_success = true;
                            setloading(true);
                            await Promise.all(sending).then((res) => {
                              // console.log(res);

                              if (
                                res.filter((e) => e === true).length ===
                                res.length
                              ) {
                                is_success = 1;
                              }
                              if (res.filter((e) => e === false).length > 0) {
                                is_success = 2;
                              }

                              if (
                                res.filter((e) => e === false).length ===
                                res.length
                              ) {
                                is_success = 3;
                              }
                            });
                            setloading(false);

                            if (is_success === 1) {
                              swal("เพิ่มเสร็จสิ้นครบถ้วน !!", {
                                icon: "success",
                              });
                            } else if (is_success === 2) {
                              swal(
                                "เพิ่มไม่ครบรายการ \nเกิดข้อผิดพลาดในบางรายการ !!",
                                {
                                  icon: "warning",
                                }
                              );
                            } else if (is_success === 3) {
                              swal(
                                "เกิดข้อผิดพลาดทุกรายการ ไม่สามารถเพิ่มได้ !!",
                                {
                                  icon: "error",
                                }
                              );
                            }
                            setdata([]);
                            setsend_data([]);
                            setfile_name("");
                            setfile("");
                          }
                        });
                      }
                    }}
                  >
                    บันทึกทั้งหมด
                  </button>
                  <button
                    className="btn btn-outline-primary btn-sm "
                    onClick={() => {
                      setdata([]);
                      setsend_data([]);
                      setfile_name("");
                      setfile("");
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
export const RestaurantEditOpenHour = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    sendToParent() {
      props.callbackOpenHour(opendata);
    },
  }));
  const [opendata, setopendata] = useState({
    sunday: {
      is_open: true,
      start_time: "08:00",
      end_time: "19:00",
    },
    monday: {
      is_open: true,
      start_time: "08:00",
      end_time: "19:00",
    },
    tuesday: {
      is_open: true,
      start_time: "08:00",
      end_time: "19:00",
    },
    wednesday: {
      is_open: true,
      start_time: "08:00",
      end_time: "19:00",
    },
    thursday: {
      is_open: true,
      start_time: "08:00",
      end_time: "19:00",
    },
    friday: {
      is_open: true,
      start_time: "08:00",
      end_time: "19:00",
    },
    saturday: {
      is_open: true,
      start_time: "08:00",
      end_time: "19:00",
    },
  });

  const receiveProps = async (parentData) => {
    // console.log('before set', parentData);
    setopendata(parentData.open_day);
  };

  useEffect(() => {
    if (Object.keys(props.parentData).length > 0) {
      // console.log("props in open", props);
      receiveProps(props.parentData);
    }
  }, [props.parentData]);

  return (
    <div>
      <div className="form-group col-12">
        <div className="row">
          <div className="form-check mt-4 ml-2" style={{ width: 30 }}>
            <input
              type="checkbox"
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.monday.is_open = !tmp.monday.is_open;
                setopendata(tmp);
                // console.log(opendata.monday.start_time);
              }}
              checked={opendata.monday.is_open}
            />
          </div>
          <div className="col-2 mt-4">วันจันทร์</div>
          <div className="col-3">
            <label htmlFor="">เวลาเปิด</label>

            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              value={opendata.monday.start_time || ""}
              onChange={(e) => {
                // console.log(object);
                let tmp = { ...opendata };
                tmp.monday.start_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
          <div className="col-3">
            <label htmlFor="">เวลาปิด</label>
            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              required
              value={opendata.monday.end_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.monday.end_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-check mt-4 ml-2" style={{ width: 30 }}>
            <input
              type="checkbox"
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.tuesday.is_open = !tmp.tuesday.is_open;
                setopendata(tmp);
              }}
              checked={opendata.tuesday.is_open}
            />
          </div>
          <div className="col-2 mt-4">วันอังคาร</div>
          <div className="col-3">
            <label htmlFor="">เวลาเปิด</label>

            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              value={opendata.tuesday.start_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.tuesday.start_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
          <div className="col-3">
            <label htmlFor="">เวลาปิด</label>
            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              required
              value={opendata.tuesday.end_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.tuesday.end_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-check mt-4 ml-2" style={{ width: 30 }}>
            <input
              type="checkbox"
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.wednesday.is_open = !tmp.wednesday.is_open;
                setopendata(tmp);
              }}
              checked={opendata.wednesday.is_open}
            />
          </div>
          <div className="col-2 mt-4">วันพุธ</div>
          <div className="col-3">
            <label htmlFor="">เวลาเปิด</label>

            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              value={opendata.wednesday.start_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.wednesday.start_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
          <div className="col-3">
            <label htmlFor="">เวลาปิด</label>
            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              required
              value={opendata.wednesday.end_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.wednesday.end_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-check mt-4 ml-2" style={{ width: 30 }}>
            <input
              type="checkbox"
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.thursday.is_open = !tmp.thursday.is_open;
                setopendata(tmp);
              }}
              checked={opendata.thursday.is_open}
            />
          </div>
          <div className="col-2 mt-4">วันพฤหัสบดี</div>
          <div className="col-3">
            <label htmlFor="">เวลาเปิด</label>

            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              value={opendata.thursday.start_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.thursday.start_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
          <div className="col-3">
            <label htmlFor="">เวลาปิด</label>
            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              required
              value={opendata.thursday.end_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.thursday.end_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-check mt-4 ml-2" style={{ width: 30 }}>
            <input
              type="checkbox"
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.friday.is_open = !tmp.friday.is_open;
                setopendata(tmp);
              }}
              checked={opendata.friday.is_open}
            />
          </div>
          <div className="col-2 mt-4">วันศุกร์</div>
          <div className="col-3">
            <label htmlFor="">เวลาเปิด</label>

            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              value={opendata.friday.start_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.friday.start_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
          <div className="col-3">
            <label htmlFor="">เวลาปิด</label>
            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              required
              value={opendata.friday.end_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.friday.end_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-check mt-4 ml-2" style={{ width: 30 }}>
            <input
              type="checkbox"
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.saturday.is_open = !tmp.saturday.is_open;
                setopendata(tmp);
              }}
              checked={opendata.saturday.is_open}
            />
          </div>
          <div className="col-2 mt-4">วันเสาร์</div>
          <div className="col-3">
            <label htmlFor="">เวลาเปิด</label>

            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              value={opendata.saturday.start_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.saturday.start_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
          <div className="col-3">
            <label htmlFor="">เวลาปิด</label>
            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              required
              value={opendata.saturday.end_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.saturday.end_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-check mt-4 ml-2" style={{ width: 30 }}>
            <input
              type="checkbox"
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.sunday.is_open = !tmp.sunday.is_open;
                setopendata(tmp);
              }}
              checked={opendata.sunday.is_open}
            />
          </div>
          <div className="col-2 mt-4">วันอาทิตย์</div>
          <div className="col-3">
            <label htmlFor="">เวลาเปิด</label>
            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              value={opendata.sunday.start_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.sunday.start_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
          <div className="col-3">
            <label htmlFor="">เวลาปิด</label>
            <input
              className="form-control"
              type="time"
              min="00:00"
              max="60:00"
              required
              value={opendata.sunday.end_time || ""}
              onChange={(e) => {
                let tmp = { ...opendata };
                tmp.sunday.end_time = e.target.value;
                setopendata(tmp);
              }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-10"></div>
        <div className="col">
          <div className="row">
            <a
              tabIndex="0"
              className="btn btn-primary  mr-2  mt-2 "
              onClick={() => {
                props.callbackOpenHour(opendata);
                props.submit(props.data);
              }}
            >
              บันทึกข้อมูล
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

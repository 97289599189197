export const api = "https://www.fastthailand.com/api/v1";
export const img_url = "https://www.fastthailand.com/api/";

export const header_token = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
};

export const google_api_key = "AIzaSyDVotVijFCnE3KbDrYZVSYqatF12Kppfo4";

export const firebaseConfig = {
  apiKey: "AIzaSyDVotVijFCnE3KbDrYZVSYqatF12Kppfo4",
  authDomain: "fast-delivery-f07de.firebaseapp.com",
  databaseURL: "https://fast-delivery-f07de-default-rtdb.firebaseio.com",
  projectId: "fast-delivery-f07de",
  storageBucket: "fast-delivery-f07de.appspot.com",
  messagingSenderId: "1094551415758",
  appId: "1:1094551415758:web:02d4c11d900ff70ba6599f",
  measurementId: "G-Z923J5W4TX",
};

export const publicVapidKey =
  "BAwEhFNHwtu2WqfyPfLor0LhUGTCw7JtJAO0-Kla_cY37vFoLbllzfea6CgiGIfNXN7ZkW5lLT5Z5W8kIE4iZSU";

export const action_option = [
  { value: "", label: "เลือก.." },
  { value: 0, label: "ลบ" },
  //   { value: 1, label: "ใช้งาน" },
  //   { value: 2, label: "ระงับใช้งาน" },
];
export const restaurant_status_option = [
  { value: "", label: "เลือก.." },
  { value: 0, label: "ระงับ" },
  { value: 1, label: "ใช้งาน" },
  { value: 2, label: "ไม่อนุมัติ" },
  { value: 3, label: "รออนุมัติ" },
];
export const menu_status_option = [
  { value: "", label: "เลือก.." },
  { value: 1, label: "ใช้งาน" },
  { value: 2, label: "ยกเลิก" },
  { value: 3, label: "หมด" },
];
export const menu_option = [
  { value: 1, label: "บังคับเลือก 1 รายการ" },
  { value: 2, label: "เลือกได้เพียงรายการเดียวเท่านั้น" },
  { value: 3, label: "เลือกได้หลายรายการ" },
];

export const wallet_option = [
  { value: -1, label: "เลือก.." },
  { value: 0, label: "ยกเลิก" },
  { value: 1, label: "อนุมัติ" },
  { value: 2, label: "รอตรวจสอบ" },
  { value: 3, label: "รอดำเนินการ" },
];

export const wallet_action = [
  { value: "", label: "เลือก.." },
  { value: "approve", label: "อนุมัติ" },
  { value: "cancel", label: "ยกเลิก" },
];

export const order_status = [
  { value: 1, label: "ออเดอร์ใหม่" },
  { value: 4, label: "กำลังจัดส่ง" },
  { value: 5, label: "ส่งสำเร็จ" },
  { value: 0, label: "ยกเลิก" },
  { value: 6, label: "ส่งไม่สำเร็จ" },
];

export const recommened_options = [
  { value: 1, label: "ใช้งาน" },
  { value: 2, label: "ระงับการใช้งาน" },
  { value: 3, label: "หมดอายุ" },
  { value: 0, label: "ยกเลิก" },
];

export const promotion_restaurant_options = [
  { value: 1, label: "ใช้งาน" },
  { value: 2, label: "หมดอายุ" },
  { value: "0", label: "ยกเลิก" },
];

export const admin_status = [
  { value: 1, label: "ใช้งาน" },
  { value: 0, label: "ระงับ" },
];

export const convertMoney = (n) => {
  return n.toLocaleString();
};

export const selectStyle = {
  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
};

export const branch_status_option = [
  { value: "", label: "เลือก.." },
  { value: 0, label: "ระงับ" },
  { value: 1, label: "ใช้งาน" },
];

export const image_size = (base64, width, height) =>
  new Promise((resolve, reject) => {
    var image = new Image();
    image.src = base64;
    image.onload = () => {
      var _height = image.height;
      var _width = image.width;

      if (_height > height && _width > width) {
        resolve(false);
      } else {
        resolve(true);
      }
    };
  });

import React, { useState, useEffect, useContext } from "react";

import { useRouteMatch, NavLink } from "react-router-dom";

import Axios from "axios";
import { api, header_token } from "../../config";
import swal from "sweetalert";
import { NotiContext } from "../../store/keep";

export const RestaurantHead = () => {
  let match = useRouteMatch();
  const { noti } = useContext(NotiContext);

  const [countall, setcountall] = useState(0);

  const getData = async () => {
    try {
      let send_data = {
        area_id: "",
        name_th: "",
        province_id: "",
        // status: [0],
      };
      let { data } = await Axios.post(
        `${api}/branch/restaurants/filter?page=${1}&limit=${1}`,
        send_data,
        header_token
      );
      setcountall(data.meta.total_count);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">
              ร้านอาหารทั้งหมด({countall.toLocaleString()})
            </h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                {(() => {
                  if (match.url.includes("add")) {
                    return "เพิ่มร้านค้า";
                  } else if (match.url.includes("approve")) {
                    return "สมัครใหม่";
                  } else {
                    return "ร้านอาหารทั้งหมด";
                  }
                })()}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to={`/restaurant`}
              >
                ร้านค้าที่ทั้งหมด
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to={`/restaurant/approve`}
              >
                สมัครใหม่
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.restaurant > 0 ? noti.restaurant : ""}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={"nav-link "}
                activeClassName="active"
                to={`/restaurant/add`}
              >
                เพิ่มร้านค้า
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                className={"nav-link "}
                activeClassName="active"
                to={`/restaurant/import`}
              >
                Import ร้านค้า
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

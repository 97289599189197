import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Axios from "axios";
import { api, header_token, img_url } from "../config";
import { SearchBar } from "./SearchBar";
import { NotiContext, RulesContext } from "../store/keep";
import moment from "moment";
import $ from "jquery";

export const Topbar = () => {
  const { push } = useHistory();
  const [load, setload] = useState(false);
  const [logo, setlogo] = useState("");
  const [admin_img, setadmin_img] = useState({});
  const { setrules } = useContext(RulesContext);
  const [is_read_all, setis_read_all] = useState(true);
  const [limit, setlimit] = useState(5);
  const [notifications, setnotifications] = useState([]);
  const { noti } = useContext(NotiContext);

  const logout = async () => {
    try {
      let { data } = await Axios.get(`${api}/user/logout`, header_token);
      // console.log(data);

      if (data.code === 200) {
        // console.log("check is login");

        localStorage.clear();

        window.location = "/";
      }
    } catch (error) {

      localStorage.clear();

      window.location = "/";
      // console.log(error.response);
    }
  };

  const unsubscribeTopic = async () => {
    try {
      let unsub = await Axios.get(
        `${api}/fcm/branch/unsubscribe/${localStorage.getItem("fcm-token")}`,
        header_token
      );
      // console.log("unsub", unsub);

      if (unsub.status === 200) {
        return true;
      }
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  const getData = async () => {
    try {
      let { data } = await Axios.get(`${api}/information`);

      setlogo(img_url + data.logo);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getProfile = async () => {
    try {
      let { data } = await Axios.get(`${api}/branch/owner`, header_token);
      setadmin_img(data.picture ? img_url + data.picture : "");
      localStorage.setItem("user_id", data.id);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  // const getNoti = async () => {
  //   setload(true);
  //   try {
  //     let { data } = await Axios.get(
  //       `${api}/notification?page=${1}&limit=${limit}`,
  //       header_token
  //     );

  //     // // console.log(data);
  //     if (data.notifications) {
  //       setnotifications(data.notifications);
  //     } else {
  //       setnotifications([]);
  //     }
  //   } catch (error) {
  //     // console.log(error.response);
  //     swal(`${error.response.data.message}`, {
  //       icon: "error",
  //       button: false,
  //     });
  //   }
  //   setload(false);
  // };

  // const getNotiBadge = async () => {
  //   try {
  //     let { data } = await Axios.get(`${api}/notification/badge`, header_token);

  //     // // console.log("abad", data);
  //     if (data.total > 0) {
  //       setis_read_all(false);
  //     }
  //   } catch (error) {
  //     // console.log(error.response);
  //     swal(`${error.response.data.message}`, {
  //       icon: "error",
  //       button: false,
  //     });
  //   }
  // };

  // const readNoti = async (id) => {
  //   try {
  //     let { data } = await Axios.get(
  //       `${api}/notification/read/${id}`,
  //       header_token
  //     );
  //     // // console.log(data);
  //     if (data.code === 200) {
  //       getNoti();
  //       getNotiBadge();
  //     }
  //   } catch (error) {
  //     // console.log(error.response);
  //     swal(`${error.response.data.message}`, {
  //       icon: "error",
  //       button: false,
  //     });
  //   }
  // };

  useEffect(() => {
    getData();
    getProfile();
    // getNotiBadge();
    // getNoti();

    $(".load-more").on("click", function (e) {
      e.stopPropagation();
      setlimit((prev) => (prev += 5));
    });
  }, []);

  // useEffect(() => {
  //   getNoti();
  // }, [limit, noti]);

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center">
        <Link className="navbar-brand brand-logo" to="/">
          <img src={logo} alt="logo" width={50} />
        </Link>
        <Link
          className="navbar-brand brand-logo-mini"
          to="/"
          style={{ margin: 0 }}
        >
          <img src={logo} alt="logo" width={50} style={{ height: "auto" }} />
        </Link>
      </div>

      <div className="navbar-menu-wrapper d-flex align-items-center ">
        <ul className="navbar-nav container-fullwidth sugg">
          <li style={{ width: "100%" }}>
            <SearchBar />
          </li>
        </ul>

        <ul className="navbar-nav navbar-nav-right">
          {/* <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center"
              id="notificationDropdown"
              tabIndex="0"
              data-toggle="dropdown"
              onClick={() => getNoti()}
            >
              <i className="mdi mdi-bell-outline mx-0 iconDrobdown"></i>
              {!is_read_all ? <span className="count"></span> : ""}
            </a>

            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
              aria-labelledby="notificationDropdown"
              style={{ width: "20rem" }}
            >
              <p className="mb-0 font-weight-bold col-12 float-left dropdown-header text-muted tx-13 border-bottom">
                แจ้งเตือน
              </p>
              {notifications.map((value, index) => {
                return (
                  <a
                    key={index}
                    tabIndex={0}
                    className="dropdown-item preview-item border-bottom noti-item"
                    onClick={(e) => {
                      readNoti(value.id);

                      if (value.action_type.includes("order")) {
                        push("/order");
                      } else if (value.action_type.includes("rider")) {
                        push("/rider/approve");
                      } else if (value.action_type.includes("restaurant")) {
                        push("/restaurant/approve");
                      } else if (value.action_type.includes("deposit")) {
                        push("/credit");
                      } else if (value.action_type.includes("withdraw")) {
                        push("/withdraw");
                      } else if (value.action_type.includes("user")) {
                        push("/user");
                      }
                    }}
                  >
                    <div className="preview-item-content">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        {value.is_read ? (
                          ""
                        ) : (
                          <div
                            style={{
                              width: 8,
                              height: 8,
                              borderRadius: 4,
                              backgroundColor: "red",
                              marginRight: 8,
                            }}
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                          }}
                        >
                          <h6 className="preview-subject font-weight-bold">
                            {value.message}
                          </h6>
                          <p className="font-weight-light mb-0 text-muted tx-12">
                            12/06/2020 l 10:29:29
                            {moment(value.created_at).format("DD/MM/YYYY")}
                            {" | "}
                            {moment(value.created_at).format("LT")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
              <a
                tabIndex={0}
                className="dropdown-item preview-item border-bottom load-more"
              >
                <div
                  className="preview-item-content "
                  style={{ margin: "0 auto" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      marginTop: 5,
                    }}
                  >
                    <h6 className="preview-subject font-weight-bold">
                      {load ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      โหลดเพิ่มเติม
                    </h6>
                  </div>
                </div>
              </a>
            </div>
          </li> */}

          <li className="nav-item nav-profile dropdown ">
            <a
              className="nav-link "
              href="#"
              data-toggle="dropdown"
              id="profileDropdown"
            >
              <img
                src={admin_img === "" ? "/images/no-img.jpg" : admin_img}
                alt="profile"
              // style={{ width: 40, height: 40 }}
              />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <Link to={`/admin_profile`} className="dropdown-item">
                <i className="mdi mdi-settings "></i>
                Settings
              </Link>
              <a
                className="dropdown-item"
                onClick={() => {
                  swal({
                    title: `ต้องการจะออกจากระบบหรือไม่ ?`,
                    confirmButtonText: "ตกลง",
                    cancelButtonText: "ยกเลิก",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then(async (willDelete) => {
                    if (willDelete) {
                      await unsubscribeTopic();
                      await logout();
                    }
                  });
                }}
              >
                <i className="mdi mdi-logout"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>

        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

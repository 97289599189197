import React, { useState, useEffect } from "react";
import { api, header_token } from "../../config";
import Axios from "axios";
import Select from "react-select";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import swal from "sweetalert";

const add_on_status = [
  { value: 1, label: "ใช้งาน" },
  { value: 0, label: "ระงับ" },
  { value: 2, label: "หมด" },
];

export const RestaurantDetailMenuListAddon = (props) => {
  const [loading, setloading] = useState(false);

  const [_data, set_data] = useState([]);

  const [option_group_addon, setoption_group_addon] = useState([]);
  const [select_group_addon, setselect_group_addon] = useState([]);
  const [old_id, setold_id] = useState("");
  const [name_addon, setname_addon] = useState("");
  const [price, setprice] = useState(0);
  const [status, setstatus] = useState({ value: 1, label: "ใช้งาน" });
  const [count, setcount] = useState(0);
  const [trigger, settrigger] = useState(true);
  const [optionId, setoptionId] = useState("");

  const getAddOn = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(
        `${api}/restaurants/${props.restaurantId}/add-on`
      );
      // console.log(data);
      set_data(data);

      // let c = 0;
      // let _tmp = {};
      // data.forEach((value) => {
      //   value.options.forEach((v) => {
      //     if (!_tmp[value.name_th]) _tmp[value.name_th] = [];

      //     _tmp[value.name_th].push(v);
      //     c++;
      //   });
      // });
      // // console.log("_tmp", _tmp);

      let tmp = [];
      let c = 0;
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
        value.options.forEach((v) => {
          c++;
        });
      });
      setcount(c);
      setoption_group_addon(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const addOptionAddon = async (add_on_id, send_data) => {
    // // console.log(add_on_id);
    // // console.log(send_data);
    try {
      let res = await Axios.post(
        `${api}/restaurants/${props.restaurantId}/add-on/${add_on_id}/options`,
        send_data,
        header_token
      );

      // // console.log();
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const editOptionAddon = async (add_on_id, option_id, send_data) => {
    try {
      let res = await Axios.put(
        `${api}/restaurants/${props.restaurantId}/add-on/${add_on_id}/options/${option_id}`,
        send_data,
        header_token
      );

      // console.log(res);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const deleteOptionAddon = async (restaurant_id, add_on_id, option_id) => {
    try {
      let res = await Axios.delete(
        `${api}/restaurants/${restaurant_id}/add-on/${add_on_id}/options/${option_id}`,
        header_token
      );

      if (res.status === 200) {
        getAddOn();
        return true;
      } else {
        return false;
      }
      // console.log(res);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  useEffect(() => {
    getAddOn();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getAddOn();
    }, 100);
  }, [trigger]);
  return (
    <div>
      <div className="row">
        <h4 className="mb-4 mt-3 ml-2">เมนูทั้งหมด {count} รายการ</h4>
      </div>
      <LoadingMask loading={loading} text={"loading..."}>
        <div style={{ minHeight: 500 }}>
          <table id="dataTable" className="table">
            <thead>
              <tr>
                <th>รายการ</th>
                <th>หมวดหมู่ Add On</th>
                <th width="150">ราคา(บาท)</th>
                <th width="80">สถานะ</th>
                <th width="100">ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              {_data.map((value, index) => {
                let tmp = [];
                value.options.map((v, idx) => {
                  let tr = (
                    <tr key={index + idx}>
                      <td>{v.name_th}</td>
                      <td>{value.name_th}</td>
                      <td>{v.price}</td>
                      <td>
                        {(() => {
                          switch (v.status) {
                            case 0:
                              return "ระงับ";

                            case 1:
                              return "ใช้งาน";

                            case 2:
                              return "รออนุมัติ";

                            default:
                              break;
                          }
                        })()}
                      </td>
                      <td>
                        <button
                          data-toggle="modal"
                          data-target=".edit-list-addon"
                          className="btn btn-outline-primary btn-sm mb-1"
                          onClick={() => {
                            setoptionId(v.id);
                            setname_addon(v.name_th);
                            setold_id(value.id);

                            // let tmp_select = [];
                            let tmp_select = {
                              value: value.id,
                              label: value.name_th,
                            };
                            // console.log(value);
                            // console.log(v);
                            // _data.map((vl) => {
                            //   console.log(vl);
                            //   vl.options.map((x) => {
                            //     if (x.name_th === v.name_th) {
                            //       tmp_select.push({
                            //         value: vl.id,
                            //         label: vl.name_th,
                            //       });
                            //     }
                            //   });
                            // });
                            setselect_group_addon(tmp_select);
                            setprice(v.price);

                            let tmp_status = {};
                            add_on_status.forEach((vl) => {
                              if (vl.value === v.status) {
                                tmp_status = {
                                  value: v.status,
                                  label: vl.label,
                                };
                              }
                            });
                            setstatus(tmp_status);
                          }}
                        >
                          แก้ไข
                        </button>

                        <button
                          className="btn btn-outline-danger btn-sm mb-1"
                          style={{ padding: "0.1rem .6rem", borderRadius: 5 }}
                          onClick={() => {
                            swal({
                              title: `ต้องการลบ  ${v.name_th} ?`,
                              // text:
                              //   "Once deleted, you will not be able to recover this imaginary file!",
                              confirmButtonText: "ลบ",
                              cancelButtonText: "ยกเลิก",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) {
                                let is_delete = await deleteOptionAddon(
                                  props.restaurantId,
                                  value.id,
                                  v.id
                                );
                                if (is_delete) {
                                  swal("ลบเสร็จสิ้น !!", {
                                    icon: "success",
                                  });
                                } else {
                                  swal("เกิดข้อผิดพลาด !!", {
                                    icon: "error",
                                  });
                                }
                              }
                            });
                          }}
                        >
                          ลบ
                        </button>
                      </td>
                    </tr>
                  );
                  tmp.push(tr);
                  return;
                });

                return <React.Fragment key={index}>{tmp}</React.Fragment>;
              })}
            </tbody>
          </table>
        </div>
      </LoadingMask>

      <div id="myModal" className="modal  create-list-addon" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <span>เพิ่มรายการ Add On</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="name_addon">รายการ</label>
                </div>
                <div className="col-lg-8">
                  <input
                    id="name_addon"
                    className="form-control"
                    name="name_addon"
                    type="text"
                    value={name_addon}
                    onChange={(e) => {
                      setname_addon(e.target.value);
                      // console.log(trigger);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="phonenumber">หมวดหมู่</label>
                </div>
                <div className="col-lg-8">
                  <Select
                    value={select_group_addon}
                    onChange={setselect_group_addon}
                    options={option_group_addon}
                    placeholder={"เลือกหมวดหมู่..."}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="price">ราคา(บาท)</label>
                </div>
                <div className="col-lg-8">
                  <input
                    id="price"
                    className="form-control"
                    name="price"
                    type="number"
                    value={price}
                    onChange={(e) => setprice(parseInt(e.target.value))}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="phonenumber">สถานะ</label>
                </div>
                <div className="col-lg-8">
                  {/*  */}
                  <Select
                    value={status}
                    onChange={setstatus}
                    options={add_on_status}
                    placeholder={"เลือกสถานะ.."}
                  />
                </div>
              </div>
            </div>

            <div className="row mr-2 mb-4">
              <div className="col-12">
                <div className="row">
                  <a
                    href="#"
                    tabIndex="0"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-outline-primary mr-2 ml-auto "
                  >
                    ยกเลิก
                  </a>
                  <a
                    href="#"
                    tabIndex="0"
                    data-dismiss="modal"
                    // aria-label="Close"
                    className="btn btn-primary  mr-2 "
                    onClick={async () => {
                      let send_data = {
                        description: "",
                        name_en: "",
                        name_th: name_addon.trim(),
                        price: price,
                        status: parseInt(status.value),
                      };
                      let sending = [];
                      select_group_addon.forEach((value) => {
                        sending.push(addOptionAddon(value.value, send_data));
                        return;
                      });

                      let is_success = true;
                      await Promise.all(sending).then((value) => {
                        if (value === false) is_success = false;
                        // console.log("promise", value);
                      });

                      if (is_success) {
                        swal("เพิ่มเสร็จสิ้น !!", {
                          icon: "success",
                        });
                      } else {
                        swal("เกิดข้อผิดพลาด !!", {
                          icon: "error",
                        });
                      }
                      // setoption_group_addon([]);
                      setselect_group_addon([]);
                      setname_addon("");
                      setprice(0);
                      setstatus({ value: 1, label: "ใช้งาน" });
                      settrigger(!trigger);
                    }}
                  >
                    บันทึก
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="editModal" className="modal  edit-list-addon" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <span>แก้ไขรายการ Add On</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="name_addon">รายการ</label>
                </div>
                <div className="col-lg-8">
                  <input
                    id="name_addon"
                    className="form-control"
                    name="name_addon"
                    type="text"
                    value={name_addon}
                    onChange={(e) => {
                      setname_addon(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="phonenumber">หมวดหมู่</label>
                </div>
                <div className="col-lg-8">
                  <Select
                    value={select_group_addon}
                    onChange={(e) => setselect_group_addon(e)}
                    options={option_group_addon}
                    placeholder={"เลือกหมวดหมู่..."}
                    // closeMenuOnSelect={false}
                    // isMulti
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="price">ราคา(บาท)</label>
                </div>
                <div className="col-lg-8">
                  <input
                    id="price"
                    className="form-control"
                    name="price"
                    type="number"
                    value={price}
                    onChange={(e) => setprice(parseInt(e.target.value))}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="phonenumber">สถานะ</label>
                </div>
                <div className="col-lg-8">
                  {/*  */}
                  <Select
                    value={status}
                    onChange={setstatus}
                    options={add_on_status}
                    placeholder={"เลือกสถานะ.."}
                  />
                </div>
              </div>
            </div>

            <div className="row mr-2 mb-4">
              <div className="col-12">
                <div className="row">
                  <button
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-outline-primary mr-2 ml-auto "
                  >
                    ยกเลิก
                  </button>
                  <button
                    data-dismiss="modal"
                    // aria-label="Close"
                    className="btn btn-primary  mr-2 "
                    onClick={async () => {
                      // console.log("select_group_addon", select_group_addon);
                      let send_data = {
                        add_on_id: select_group_addon.value,
                        description: "",
                        name_en: "",
                        name_th: name_addon.trim(),
                        price: price,
                        status: parseInt(status.value),
                      };
                      let sending = [];

                      sending.push(
                        editOptionAddon(old_id, optionId, send_data)
                      );

                      await Promise.all(sending);

                      setselect_group_addon([]);
                      setoptionId("");
                      setname_addon("");
                      setprice(0);
                      setstatus({ value: 1, label: "ใช้งาน" });
                      settrigger(!trigger);
                    }}
                  >
                    แก้ไข
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState, useContext } from "react";
import firebase from "firebase/app";
import "firebase/messaging";
import Axios from "axios";
import swal from "sweetalert";
import { api, firebaseConfig, header_token, publicVapidKey } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotiContext } from "./keep";

const GoToPage = (props) => {
  const goTo = (link) => {
    toast.dismiss();
  };

  return <div onClick={() => goTo(props.link)}>{props.text}</div>;
};

export const NotificationFirebase = () => {
  const sw = navigator.serviceWorker;
  const notify = (text, link) =>
    toast.info(<GoToPage text={text} link={link} />);
  const { setnoti } = useContext(NotiContext);
  const [fcmtoken, setfcmtoken] = useState(localStorage.getItem("fcm-token"));
  const [is_register] = useState(localStorage.getItem("register-fcm-token"));

  const getTokenAndSubscribe = async () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    const messaging = firebase.messaging();

    messaging.usePublicVapidKey(publicVapidKey);

    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      if (!fcmtoken) {
        messaging.getToken().then(async (token) => {
          setfcmtoken(token);
          localStorage.setItem("fcm-token", token);
          await registerToken();
          await subscribeTopic(token);

          await receiveNotiAll();
        });
      } else {
        if (localStorage.getItem("token")) {
          receiveNotiAll();
        }
      }
    } else if (Notification.permission !== "denied") {
      // console.log("no");
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          if (!fcmtoken) {
            messaging.getToken().then(async (token) => {
              setfcmtoken(token);
              localStorage.setItem("fcm-token", token);
              await registerToken();
              await subscribeTopic(token);

              await receiveNotiAll();
            });
          } else {
            if (localStorage.getItem("token")) {
              receiveNotiAll();
            }
          }
        }
      });
    }

    messaging.onMessage((body) => {
      let { data } = body;
      // console.log("Message received. ", data);

      let type = data.type;
      let action_type = data.action_type;
      let msg = data.message;

      receiveNoti(type, action_type, msg);
    });
  };

  const onBackgroundMessage = () => {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      const channel = new BroadcastChannel("sw-messages");
      channel.addEventListener("message", ({ data }) => {
        let _data = data.data.data;
        // console.log("sw-messages", _data);

        let type = _data.type;
        let action_type = _data.action_type;
        let msg = _data.message;

        receiveNoti(type, action_type, msg);
      });
    }
  };

  const receiveNoti = async (type, action_type, msg) => {
    try {
      let count;

      if (
        action_type.includes("pick_up") ||
        action_type.includes("shipping") ||
        action_type.includes("new_order") ||
        action_type.includes("cancel_order")
      ) {
        count = await badgeOrderCar();
        setnoti((prevState) => ({
          ...prevState,
          car_service: count,
          getdata: "car_service",
        }));
      }

      if (type.includes("order")) {
        count = await badgeOrder();
        setnoti((prevState) => ({
          ...prevState,
          order: count,
          getdata: "order",
        }));
      }

      if (type.includes("restaurant")) {
        count = await badgeRestaurant();
        setnoti((prevState) => ({
          ...prevState,
          restaurant: count,
          getdata: "restaurant",
        }));
        notify(`🏠 ${msg}`, "/restaurant/approve");
      }

      if (type.includes("rider")) {
        count = await badgeRider();
        setnoti((prevState) => ({
          ...prevState,
          rider: count,
          getdata: "rider",
        }));
        notify(` 🏍️ ${msg} `, "/rider/approve");
      }

      // if (type.includes("user")) {
      //   count = await badgeUser();
      //   setnoti((prevState) => ({
      //     ...prevState,
      //     user: count,
      //   }));
      // }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      localStorage.removeItem("token");
      setTimeout(() => {
        window.location = "/";
      }, 10);
    }
  };

  const badgeOrder = async () => {
    try {
      let { data } = await Axios.get(`${api}/branch/badge/order`, header_token);
      // console.log("badgeOrder", data);
      return data.total;
    } catch (error) {
      console.log(error);
      localStorage.removeItem("token");
      setTimeout(() => {
        window.location = "/";
      }, 10);
    }
  };

  const badgeRestaurant = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/branch/badge/restaurant`,
        header_token
      );
      return data.total;
    } catch (error) {
      console.log(error);
      localStorage.removeItem("token");
      setTimeout(() => {
        window.location = "/";
      }, 10);
    }
  };

  const badgeOrderCar = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/branch/badge/order-car-service`,
        header_token
      );
      return data.total;
    } catch (error) {
      console.log(error);
      localStorage.removeItem("token");
      setTimeout(() => {
        window.location = "/";
      }, 10);
    }
  };

  const badgeRider = async () => {
    try {
      let { data } = await Axios.get(`${api}/branch/badge/rider`, header_token);
      // console.log("rider", data);
      return data.total;
    } catch (error) {
      console.log(error);
      localStorage.removeItem("token");
      setTimeout(() => {
        window.location = "/";
      }, 10);
    }
  };

  // const badgeUser = async () => {
  //   try {
  //     let { data } = await Axios.get(`${api}/branch/badge/user`, header_token);
  //     // console.log("user", data);
  //     return data.total;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const receiveNotiAll = async (type) => {
    try {
      let count;

      count = await badgeRider();
      setnoti((prevState) => ({
        ...prevState,
        rider: count,
      }));

      count = await badgeRestaurant();
      setnoti((prevState) => ({
        ...prevState,
        restaurant: count,
      }));

      count = await badgeOrder();
      setnoti((prevState) => ({
        ...prevState,
        order: count,
      }));

      count = await badgeOrderCar();
      setnoti((prevState) => ({
        ...prevState,
        car_service: count,
      }));

      // count = await badgeUser();
      // setnoti((prevState) => ({
      //   ...prevState,
      //   user: count,
      // }));
    } catch (error) {
      console.log(error);
    }
  };

  const registerToken = async (token) => {
    if (!is_register && token) {
      try {
        let { data } = await Axios.get(
          `${api}/fcm/register/${token}`,
          header_token
        );
        localStorage.setItem("register-fcm-token", true);
      } catch (error) {
        console.log(error);
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  const subscribeTopic = async (token) => {
    try {
      let { data } = await Axios.get(
        `${api}/fcm/branch/subscribe/${token}`,
        header_token
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onBackgroundMessage();
  }, []);

  useEffect(() => {
    if (sw) {
      if ("serviceWorker" in navigator) {
        window.addEventListener("load", function () {
          navigator.serviceWorker.register("/firebase-messaging-sw.js").then(
            function (registration) {
              getTokenAndSubscribe();
            },
            function (err) {
              console.log("ServiceWorker registration failed: ", err);
            }
          );
        });
      }
    }
  }, [sw]);

  return (
    <span>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </span>
  );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import moment from "moment";
import { api, img_url, header_token } from "../../config";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import swal from "sweetalert";

import { RestaurantHead } from "./RestaurantHead";
import { NotiContext } from "../../store/keep";

const action_option = [
  { value: "approve", label: "อนุมัติ" },
  { value: "cancel", label: "ไม่อนุมัติ" },
];

export const RestaurantApprove = () => {
  const checkAllRef = useRef(null);
  const { noti } = useContext(NotiContext);

  const [loading, setloading] = useState(false);

  const [img, setimg] = useState("");
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [total_page, settotal_page] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_name, setfilter_name] = useState("");
  const [action, setaction] = useState({ value: "", label: "เลือก.." });

  const getData = async () => {
    setloading(true);

    let send_data = {
      // area_id: '',
      name_th: filter_name,
      // province_id: filter_province.value,
      status: [3],
    };
    try {
      let { data } = await Axios.post(
        `${api}/branch/restaurants/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );

      // console.log(data);
      let page_count = Math.ceil(data.meta.total_count / limit);
      settotal_page(page_count);
      settotal_count(data.meta.total_count);

      let tmp = [];
      data.restaurants.forEach((value) => {
        value.checked = false;
        tmp.push(value);
      });
      // console.log(tmp);
      setdata(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const approveRestaurant = async (restaurant_id, status) => {
    try {
      let res = await Axios.get(
        `${api}/restaurants/${restaurant_id}/update-status?status=${status}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_name]);

  useEffect(() => {
    getData();
  }, [current_page]);

  useEffect(() => {
    if (noti.getdata === "restaurant") {
      getData();
    }
  }, [noti]);

  return (
    <div>
      <RestaurantHead />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ชื่อ"
                      value={filter_name}
                      onChange={(e) => {
                        setfilter_name(e.target.value);
                      }}
                    />
                  </div>

                  <div className="ml-2 mr-3">
                    <div className="row">
                      <div style={{ width: 150 }}>
                        <span className="tx-13 text-muted">ดำเนินการ</span>

                        <Select
                          value={action}
                          onChange={(e) => {
                            setaction(e);
                          }}
                          options={action_option}
                          placeholder={"ดำเนินการ.."}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="ml-2 ">
                    <div>
                      <button
                        className="btn btn-primary "
                        style={{ marginTop: 23 }}
                        onClick={async () => {
                          let count = data.filter((e) => e.checked === true);
                          let txt =
                            action.value === "approve"
                              ? "อนุมัติ"
                              : "ไม่อนุมัติ";
                          if (action.value && count.length > 0) {
                            swal({
                              title: `ต้องการ${txt}ที่เลือกหรือไม่ ?`,
                              confirmButtonText: `${txt}`,
                              cancelButtonText: "ยกเลิก",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async (willApprove) => {
                              if (willApprove) {
                                let sending = [];
                                data.forEach((value) => {
                                  if (value.checked === true) {
                                    sending.push(
                                      approveRestaurant(
                                        value.id,
                                        action.value === "approve" ? 1 : 2
                                      )
                                    );
                                  }
                                });

                                let is_success = true;
                                await Promise.all(sending).then((res) => {
                                  console.log(res);
                                  if (
                                    res.filter((e) => e === true).length ===
                                      0 ||
                                    res.length === 0
                                  )
                                    is_success = false;
                                });
                                if (is_success) {
                                  getData();
                                  swal("เสร็จสิ้น !!", {
                                    icon: "success",
                                  });
                                } else {
                                  swal("เกิดข้อผิดพลาด !!", {
                                    icon: "error",
                                  });
                                }
                              }
                            });
                          } else {
                            alert("เลือกตัวดำเนินการ");
                          }
                        }}
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                  <div className="ml-2 ">
                    <div>
                      <a
                        href="#"
                        tabIndex="0"
                        id="search"
                        className="btn btn-primary"
                        style={{ marginTop: 23 }}
                      >
                        Export All
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="table-responsive">
                    <table id="dataTable" className="table ">
                      <thead>
                        <tr>
                          <th style={{ minWidth: 30 }}>
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                console.log(checkAllRef.current.checked);
                                let tmp = [...data];
                                let _tmp = [];
                                tmp
                                  .slice(
                                    (current_page - 1) * limit,
                                    current_page * limit
                                  )
                                  .forEach((value, index) => {
                                    value.checked = checkAllRef.current.checked;
                                    _tmp.push(value);
                                  });

                                setdata(tmp);
                                console.log(data);
                              }}
                            />
                          </th>
                          <th style={{ minWidth: 50 }}>รูป</th>
                          <th style={{ minWidth: 50 }}>รหัส</th>
                          <th style={{ minWidth: 200 }}>ชื่อ</th>
                          <th style={{ minWidth: 250 }}>ที่อยู่</th>
                          <th style={{ minWidth: 120 }}>ตำบล/แขวง</th>
                          <th style={{ minWidth: 120 }}>อำเภอ/เขต</th>
                          <th style={{ minWidth: 125 }}>จังหวัด</th>
                          <th style={{ minWidth: 120 }}>รหัสไปรษณีย์</th>
                          <th style={{ minWidth: 180 }}>เบอร์โทร</th>
                          <th style={{ minWidth: 200 }}>อีเมล์</th>
                          <th style={{ minWidth: 150 }}>พื้นที่</th>
                          <th style={{ minWidth: 120 }}>เครดิต</th>
                          <th style={{ minWidth: 80 }}>ยอดขาย</th>
                          <th style={{ minWidth: 80 }}>เมนู</th>
                          <th style={{ minWidth: 100 }}>คะแนนรีวิว</th>
                          <th style={{ minWidth: 180 }}>ประเภทอาหาร</th>
                          <th style={{ minWidth: 120 }}>วันที่</th>
                          <th style={{ minWidth: 80 }}>สถานะ</th>
                          <th style={{ minWidth: 80 }}>อนุมัติ</th>
                          <th style={{ minWidth: 120 }}>ไม่อนุมัติ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked ? "#e8eaed" : "",
                              }}
                            >
                              <td style={{ minWidth: 30 }}>
                                <input
                                  type="checkbox"
                                  checked={value.checked}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>

                              <td>
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.picture}`
                                    );
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="lazy img-responsive"
                                    src={
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.picture}`
                                    }
                                  />
                                </div>
                              </td>
                              <td>{value.restaurant_id}</td>
                              <td>
                                <Link
                                  // target="_blank"
                                  to={`/restaurant/detail/${value.id}/history`}
                                >
                                  <span>{value.name_th}</span>
                                </Link>
                              </td>
                              <td>{value.location.address}</td>
                              <td>{value.location.sub_district.name_th}</td>
                              <td>{value.location.district.name_th}</td>
                              <td>{value.location.province.name_th}</td>
                              <td>{value.location.sub_district.zip_code}</td>
                              <td>{value.phone_numbers}</td>
                              <td>{value.email}</td>
                              <td>{value.area.name_th}</td>
                              <td className="text-success dt-cradit">
                                ฿{value.credit}
                              </td>
                              <td>
                                <Link
                                  // tabIndex="0"
                                  className="btn btn-outline-primary mb-1"
                                  // target="_blank"
                                  to={`/restaurant/detail/${value.id}/sale`}
                                >
                                  ฿{0}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  // tabIndex="0"
                                  className="btn btn-outline-primary mb-1"
                                  // target="_blank"
                                  to={`/restaurant/detail/${value.id}/menu`}
                                >
                                  {value.menu_count}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  // tabIndex="0"
                                  className="btn btn-outline-primary mb-1"
                                  // target="_blank"
                                  to={`/restaurant/detail/${value.id}/rate`}
                                >
                                  {value.user_rating.rating}
                                </Link>
                              </td>
                              <td>
                                {value.categories.map((v, idx) => {
                                  let comma = "";
                                  if (idx !== value.categories.length - 1)
                                    comma = ", ";
                                  return (
                                    <span key={idx}>{v.title + comma}</span>
                                  );
                                })}
                              </td>
                              <td>
                                {moment(value.register_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return (
                                        <div className="badge badge-warning">
                                          ระงับ
                                        </div>
                                      );

                                    case 1:
                                      return (
                                        <div className="badge badge-success">
                                          ใช้งาน
                                        </div>
                                      );

                                    case 2:
                                      return (
                                        <div className="badge badge-danger">
                                          ไม่อนุมัติ
                                        </div>
                                      );

                                    case 3:
                                      return (
                                        <div className="badge badge-primary">
                                          รออนุมัติ
                                        </div>
                                      );

                                    default:
                                      break;
                                  }
                                })()}
                              </td>

                              <td>
                                <button
                                  className="btn btn-outline-primary btn-sm mb-1"
                                  onClick={() => {
                                    swal({
                                      title: `ต้องการอนุมัติหรือไม่ ?`,
                                      confirmButtonText: "อนุมัติ",
                                      cancelButtonText: "ยกเลิก",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then(async (willApprove) => {
                                      if (willApprove) {
                                        let is_success = await approveRestaurant(
                                          value.id,
                                          1
                                        );

                                        if (is_success) {
                                          getData();
                                          swal("เสร็จสิ้น !!", {
                                            icon: "success",
                                          });
                                        } else {
                                          swal("เกิดข้อผิดพลาด !!", {
                                            icon: "error",
                                          });
                                        }
                                      }
                                    });
                                  }}
                                >
                                  อนุมัติ
                                </button>
                              </td>
                              <td>
                                <button
                                  className="btn btn-outline-warning small-buttom btn-sm mb-1"
                                  onClick={() => {
                                    swal({
                                      title: `ต้องการไม่อนุมัติหรือไม่ ?`,
                                      confirmButtonText: "ไม่อนุมัติ",
                                      cancelButtonText: "ยกเลิก",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then(async (willApprove) => {
                                      if (willApprove) {
                                        let is_success = await approveRestaurant(
                                          value.id,
                                          2
                                        );

                                        if (is_success) {
                                          getData();
                                          swal("เสร็จสิ้น !!", {
                                            icon: "success",
                                          });
                                        } else {
                                          swal("เกิดข้อผิดพลาด !!", {
                                            icon: "error",
                                          });
                                        }
                                      }
                                    });
                                  }}
                                >
                                  ไม่อนุมัติ
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>

              <div className="mt-2" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={total_page}
                  onPageChange={(e) => setcurrent_page(e.selected + 1)}
                  forcePage={current_page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                alt=""
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExampleImportRestaurant = () => {
  const data = [
    {
      name: "เรือนอาหารทะเลวังมุข",
      address: "31 หมู่11",
      sub_district: "พระบรมมหาราชวัง",
      district: "พระนคร",
      province: "กรุงเทพมหานคร",
      zip_code: "10200",
      email: "test_restaurant@gmail.com",
      phone_number: "0982714652",
      lat: "13.8396538",
      lng: "100.861831",
      password: "123456789",
    },
    {
      name: "ร้านส้มตำแซ๋บเวอร์",
      address: "13 หมู่1",
      sub_district: "บางกรวย",
      district: "บางกรวย",
      province: "นนทบุรี",
      zip_code: "11130",
      email: "test_test_restaurant@gmail.com",
      phone_number: "0882714652",
      lat: "13.8396538",
      lng: "100.861831",
      password: "123456789",
    },
  ];

  return (
    <ExcelFile
      filename={`Restaurant_import_Example`}
      element={
        <a
          tabIndex="0"
          // type="submit"
          // className="btn btn-primary mr-2"
          // style={{ marginTop: 23, display: "none" }}
        >
          ดาวน์โหลดไฟล์ตัวอย่าง
        </a>
      }
    >
      <ExcelSheet data={data} name="Rider_example">
        <ExcelColumn label="ชื่อ" value="name" />
        {/* <ExcelColumn label="เลขที่บัตรประชาชน" value="identification_number" /> */}
        {/* <ExcelColumn label="วัน/เดือน/ปีเกิด" value="birth_date" /> */}
        {/* <ExcelColumn label="วุฒิการศึกษา" value="degree" /> */}
        <ExcelColumn label="ที่อยู่" value="address" />
        <ExcelColumn label="ตำบล" value="sub_district" />
        <ExcelColumn label="อำเภอ" value="district" />
        <ExcelColumn label="จังหวัด" value="province" />
        <ExcelColumn label="รหัสไปรษณีย์" value="zip_code" />
        {/* <ExcelColumn label="พื้นที่" value="area" />
        <ExcelColumn label="ทีมงาน" value="team" /> */}
        <ExcelColumn label="เบอร์โทรศัพท์" value="phone_number" />
        {/* <ExcelColumn label="ID Line" value="line_id" /> */}
        <ExcelColumn label="อีเมล์" value="email" />
        <ExcelColumn label="พิกัดละติจูด" value="lat" />
        <ExcelColumn label="พิกัดลองจิจูด" value="lng" />
        {/* <ExcelColumn label="เครดิต" value="credit" /> */}
        {/* <ExcelColumn label="แต้ม" value="point" /> */}
        {/* <ExcelColumn label="ประเภท" value="employee_type" /> */}
        {/* <ExcelColumn label="วันที่สมัคร" value="register" /> */}
        {/* <ExcelColumn label="พาหนะ" value="vehicle_type" /> */}
        {/* <ExcelColumn label="เลขทะเบียน" value="license_plate" /> */}
        {/* <ExcelColumn label="รุ่น" value="vehicle_make" />
        <ExcelColumn label="สี" value="vehicle_color" />
        <ExcelColumn label="ชื่อผู้ใช้" value="username" /> */}
        <ExcelColumn label="รหัสผ่าน" value="password" />
        {/* <ExcelColumn label="สถานะ" value="status" /> */}
      </ExcelSheet>
    </ExcelFile>
  );
};

import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExampleImportRider = () => {
  const data = [
    {
      name: "นายกนก ขนมหวาน",
      identification_number: "192990067816",
      birth_date: "16/12/1993",
      address: "31 หมู่11",
      sub_district: "พระบรมมหาราชวัง",
      district: "พระนคร",
      province: "กรุงเทพมหานคร",
      zip_code: "10200",
      email: "test@gmail.com",
      phone_number: "0982714652",
      line_id: "test_rider",
      vehicle_type: "รถจักรยานยนต์",
      vehicle_make: "wave 110i",
      vehicle_color: "ดำ",
      license_plate: "1กข9999",
      // register: "17/10/2020",
      username: "test_rider_01",
      password: "123456789",
    },
    {
      name: "นายกะทิ ราดขนมหวาน",
      identification_number: "1328700281722",
      birth_date: "20/12/1993",
      address: "13 หมู่1",
      sub_district: "บางกรวย",
      district: "บางกรวย",
      province: "นนทบุรี",
      zip_code: "11130",
      email: "test_test@gmail.com",
      phone_number: "0882714652",
      line_id: "test_rider2",
      vehicle_type: "รถยนต์",
      vehicle_make: "vios",
      vehicle_color: "ขาว",
      license_plate: "บฉ1111",
      // register: "27/10/2020",
      username: "test_rider_02",
      password: "123456789",
    },
  ];

  return (
    <ExcelFile
      filename={`Rider_import_Example`}
      element={
        <a
          tabIndex="0"
          // type="submit"
          // className="btn btn-primary mr-2"
          // style={{ marginTop: 23, display: "none" }}
        >
          ดาวน์โหลดไฟล์ตัวอย่าง
        </a>
      }
    >
      <ExcelSheet data={data} name="Rider_example">
        <ExcelColumn label="ชื่อ-นามสกุล" value="name" />
        <ExcelColumn label="เลขที่บัตรประชาชน" value="identification_number" />
        <ExcelColumn label="วัน/เดือน/ปีเกิด" value="birth_date" />
        {/* <ExcelColumn label="วุฒิการศึกษา" value="degree" /> */}
        <ExcelColumn label="ที่อยู่" value="address" />
        <ExcelColumn label="ตำบล" value="sub_district" />
        <ExcelColumn label="อำเภอ" value="district" />
        <ExcelColumn label="จังหวัด" value="province" />
        <ExcelColumn label="รหัสไปรษณีย์" value="zip_code" />
        {/* <ExcelColumn label="พื้นที่" value="area" />
        <ExcelColumn label="ทีมงาน" value="team" /> */}
        <ExcelColumn label="เบอร์โทรศัพท์" value="phone_number" />
        <ExcelColumn label="ID Line" value="line_id" />
        <ExcelColumn label="อีเมล์" value="email" />
        {/* <ExcelColumn label="เครดิต" value="credit" /> */}
        {/* <ExcelColumn label="แต้ม" value="point" /> */}
        {/* <ExcelColumn label="ประเภท" value="employee_type" /> */}
        {/* <ExcelColumn label="วันที่สมัคร" value="register" /> */}
        <ExcelColumn label="พาหนะ" value="vehicle_type" />
        <ExcelColumn label="เลขทะเบียน" value="license_plate" />
        <ExcelColumn label="รุ่น" value="vehicle_make" />
        <ExcelColumn label="สี" value="vehicle_color" />
        <ExcelColumn label="ชื่อผู้ใช้" value="username" />
        <ExcelColumn label="รหัสผ่าน" value="password" />
        {/* <ExcelColumn label="สถานะ" value="status" /> */}
      </ExcelSheet>
    </ExcelFile>
  );
};

import React, { useEffect, useRef } from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportOrderData = ({ data, trigger, settrigger }) => {
  const exportButton = useRef();

  useEffect(() => {
    if (trigger) {
      exportButton.current.click();
      settrigger(false);
    }
  }, [trigger]);

  return (
    <ExcelFile
      filename={`Order_export`}
      element={
        <button
          type="submit"
          className="btn btn-primary mr-2"
          style={{ marginTop: 23, display: "none" }}
          ref={exportButton}
        >
          Download Data
        </button>
      }
    >
      <ExcelSheet data={data} name="Restaurant">
        <ExcelColumn label="Order No" value="order_no" />
        <ExcelColumn label="เวลา" value="order_time" />
        <ExcelColumn label="การจัดส่ง" value="delivery" />
        <ExcelColumn label="ร้านอาหาร" value="restaurant" />
        <ExcelColumn label="รายการอาหาร" value="menu" />
        <ExcelColumn label="ผู้รับ" value="user" />
        <ExcelColumn label="ผู้ส่ง" value="rider" />
        <ExcelColumn label="จำนวน" value="menu_count" />
        <ExcelColumn label="ค่าอาหาร" value="food_cost" />
        <ExcelColumn label="ค่าจัดส่ง" value="shipping_cost" />
        <ExcelColumn label="รวม" value="total" />
        <ExcelColumn label="ระยะทาง" value="distance" />
        <ExcelColumn label="สถานะ" value="status" />
      </ExcelSheet>
    </ExcelFile>
  );
};

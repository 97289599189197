import React, { useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import swal from "sweetalert";
import { header_token, api } from "../../config";
import Axios from "axios";

export const SendingSending = (props) => {
  const checkAllRef = useRef(null);

  const [filter_order, setfilter_order] = useState("");
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);

  const cancelOrder = async (order_id) => {
    try {
      let res = await Axios.get(
        `${api}/branch/order/cancel/${order_id}`,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    // console.log(props);
    if (props.data) {
      setdata(props.data);

      let cal_page_count = Math.ceil(props.data.length / limit);
      setpage_count(cal_page_count);
    }
  }, [props]);

  return (
    <div>
      <div className="row">
        <div className="ml-auto">
          <div className="form-row align-items-center mb-2">
            <div className="col ml-1 mr-3">
              <div className="row">
                <div style={{ width: 150 }} className="">
                  <span className="tx-13 text-muted">ดำเนินการ</span>
                  <Select
                    value={filter_order}
                    onChange={async (e) => {
                      setfilter_order(e);
                    }}
                    options={[{ value: "cancel", label: "ยกเลิก" }]}
                    placeholder={"เลือก..."}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          data-toggle="modal"
          data-target={`${
            filter_order.value === "assign" ? ".assign-order" : ""
          }`}
          className="btn btn-primary mr-2 "
          style={{ height: 42, marginTop: 20 }}
          onClick={async () => {
            if (
              !filter_order.value ||
              data.filter((e) => e.checked === true).length === 0
            ) {
              alert("เลือกตัวดำเนินการ");
              return;
            }

            if (filter_order.value === "cancel") {
              swal({
                title: `ต้องการยกเลิกออเดอร์ที่เลือกหรือไม่ ?`,
                confirmButtonText: "ใช่",
                cancelButtonText: "ไม่",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  let sending = [];
                  data.forEach((value) => {
                    if (value.checked === true) {
                      sending.push(cancelOrder(value.id));
                    }
                  });

                  let is_success = true;
                  await Promise.all(sending).then((res) => {
                    // console.log(res);
                    if (
                      res.filter((e) => e === true).length === 0 ||
                      res.length === 0
                    )
                      is_success = false;
                  });

                  if (is_success) {
                    swal("ยกเลิกเสร็จสิ้น !!", {
                      icon: "success",
                    });
                  } else {
                    swal("เกิดข้อผิดพลาด !!", {
                      icon: "error",
                    });
                  }
                }
              });
            }
          }}
        >
          ดำเนินการ
        </button>
      </div>

      <div className="table-responsive">
        <table id="dataTable" className=" table text-wrap" cellSpacing="0">
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>
                <input
                  type="checkbox"
                  className="selectAll "
                  name="selectAll"
                  defaultChecked={false}
                  ref={checkAllRef}
                  onClick={(e) => {
                    // console.log(checkAllRef.current.checked);
                    let tmp = [...data];
                    let _tmp = [];
                    tmp
                      .slice((current_page - 1) * limit, current_page * limit)
                      .forEach((value, index) => {
                        value.checked = checkAllRef.current.checked;
                        _tmp.push(value);
                      });

                    setdata(tmp);
                    // console.log(data);
                  }}
                />
              </th>
              <th style={{ minWidth: 60 }}>Order No.</th>
              <th style={{ minWidth: 120 }}>เวลา</th>
              <th style={{ minWidth: 300 }}>ร้านอาหาร</th>
              <th style={{ minWidth: 300 }}>สถานที่จัดส่ง</th>
              <th style={{ minWidth: 80 }}>ค่าอาหาร</th>
              <th style={{ minWidth: 80 }}>ค่าจัดส่ง</th>
              <th style={{ minWidth: 120 }}>สถานะ</th>
              <th style={{ minWidth: 150 }}>ดำเนินการ</th>
            </tr>
          </thead>
          <tbody>
            {data
              .slice((current_page - 1) * limit, current_page * limit)
              .map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={value.checked || ""}
                        onChange={(e) => {
                          let tmp = [...data];
                          data.forEach((vl, idx) => {
                            if (vl.id === value.id) {
                              tmp[idx].checked = !tmp[idx].checked;
                              setdata(tmp);
                            }
                          });
                        }}
                      />
                    </td>
                    <td>
                      <a
                        tabIndex={0}
                        onClick={() =>
                          props.pushToLink(`/order/detail/${value.id}`)
                        }
                      >
                        {value.order_no}
                      </a>{" "}
                    </td>
                    <td>
                      <i className="fa fa-clock-o mr-1"></i>{" "}
                      {moment(value.created_at).format("LT")}
                    </td>
                    <td style={{ textAlign: "left" }}>
                      <a
                        tabIndex={0}
                        onClick={() =>
                          props.pushToLink(
                            `/restaurant/detail/${value.restaurant.id}/history`
                          )
                        }
                      >
                        {value.restaurant.name_th}
                        <br />
                        {value.restaurant.phone_numbers}
                      </a>
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {value.contact_name}
                      <br />
                      {value.to.address}
                      {", "}
                      {`ตำบล${value.to.sub_district.name_th}`}
                      {", "}
                      {`อำเภอ${value.to.district.name_th}`}
                      {", "}
                      {`จังหวัด${value.to.province.name_th}`}
                      {", "}
                      {`${value.to.sub_district.zip_code}`}{" "}
                    </td>
                    <td>฿{value.food_cost}</td>
                    <td>฿{value.shipping_cost}</td>
                    <td>
                      {(() => {
                        if (value.type === 0) return "ออเดอร์ล่วงหน้า";

                        switch (value.status) {
                          case 0:
                            return "ยกเลิก";
                          case 1:
                            return "ออเดอร์ใหม่";
                          case 4:
                            return "กำลังจัดส่ง";
                          case 5:
                            return "ส่งสำเร็จ";
                          case 6:
                            return "ส่งไม่สำเร็จ";

                          default:
                            break;
                        }
                      })()}
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          props.pushToLink(`/order/detail/${value.id}`)
                        }
                        className="btn btn-outline-primary btn-sm"
                      >
                        รายละเอียด
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="mt-2" style={{ float: "right" }}>
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={<span className="gap">...</span>}
          pageCount={page_count}
          onPageChange={(e) => setcurrent_page(e.selected + 1)}
          forcePage={current_page - 1}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
        />
      </div>
      <br />
      <br />
    </div>
  );
};

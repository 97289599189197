import React, { useState, useEffect } from "react";

import { api, img_url, header_token } from "../../config";
import swal from "sweetalert";
import Axios from "axios";
import { useForm } from "react-hook-form";

export const SettingDetail = () => {
  const { register, handleSubmit, errors, trigger } = useForm();

  const [name_th, setname_th] = useState("");
  const [name_en, setname_en] = useState("");
  const [address, setaddress] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [email, setemail] = useState("");
  const [tax_number, settax_number] = useState("");
  const [logo, setlogo] = useState({ preview: "", base64: "" });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //

  const getData = async () => {
    try {
      let { data } = await Axios.get(`${api}/information`);
      // console.log("from get", data);
      let tmp = { ...data };
      setname_th(tmp.name_th);
      setname_en(tmp.name_en);
      setlogo({ preview: tmp.logo, base64: "" });
      setaddress(tmp.location.address);
      setphone_number(tmp.phone_number);
      setemail(tmp.email);
      settax_number(tmp.tax_number);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = async (data) => {
    // // console.log("submit", data);

    let send_data = {
      email: email,
      location: {
        address: address,
        district_id: "",
        latitude: 0,
        longitude: 0,
        province_id: "",
        sub_district_id: "",
      },
      logo: logo.base64 === "" ? logo.preview : logo.base64,
      name_en: name_en,
      name_th: name_th,
      phone_number: phone_number,
      tax_number: tax_number,
    };
    // console.log("send_data", send_data);
    try {
      let res = await Axios.put(`${api}/information`, send_data, header_token);
      // console.log(res);
      if (res.status === 201 || res.status === 200) {
        // // console.log("okkk");
        swal("แก้ไขเสร็จสิ้น !!", {
          icon: "success",
        });
        // history.push("/restaurant");
        //localhost:3000/restaurant/detail/5f3a44f2ef41ff9eb70e327a/history
        window.location = `/setting`;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  // // console.log(errors);

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group row">
                <label
                  htmlFor="name_th"
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  ชื่อภาษาไทย
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name_th ? "error-input" : ""
                    }`}
                    name="name_th"
                    placeholder="ชื่อบริษัทภาษาไทย"
                    ref={register({ required: true })}
                    value={name_th}
                    onChange={(e) => setname_th(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="exampleInputUsername2"
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  ชื่อภาษาอังกฤษ
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name_en ? "error-input" : ""
                    }`}
                    name="name_en"
                    placeholder="ชื่อบริษัทภาษาอังกฤษ"
                    ref={register({ required: true })}
                    value={name_en}
                    onChange={(e) => setname_en(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="name">โลโก้บริษัท</label>
                </div>
                <div className="col-lg-5">
                  <img
                    id="img-upload"
                    alt=""
                    src={
                      logo.base64 === ""
                        ? logo.preview === ""
                          ? ""
                          : img_url + logo.preview
                        : logo.preview
                    }
                    style={{ maxWidth: "100%", maxHeight: 500 }}
                  />
                  <div className="row">
                    <div className="col-10">
                      <div className="input-group">
                        <input
                          type="file"
                          id="logo"
                          className={`form-control ${
                            errors.logo ? "error-input" : ""
                          }`}
                          accept=".png, .jpg, .jpeg"
                          onChange={async (e) => {
                            // // console.log(e.target.files[0]);

                            if (e.target.files.length) {
                              await setlogo({
                                preview: URL.createObjectURL(e.target.files[0]),
                                base64: await toBase64(e.target.files[0]),
                              });
                              trigger("logo");
                              // console.log(logo);
                            }
                          }}
                          ref={(e) => {
                            register(
                              { name: "logo" },
                              {
                                validate: (value) => {
                                  // console.log("test");
                                  return logo.base64 || logo.preview
                                    ? true
                                    : false;
                                },
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-light btn-sm mt-1"
                        onClick={() => {
                          setlogo({
                            preview: "",
                            base64: "",
                          });
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="exampleInputUsername2"
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  ที่อยู่
                </label>
                <div className="col-sm-4">
                  <textarea
                    id="validationCustom03"
                    type="text"
                    className={`form-control ${
                      errors.address ? "error-input" : ""
                    }`}
                    name="address"
                    placeholder="ที่อยู่บริษัท"
                    style={{ height: 100 }}
                    maxLength="200"
                    data-bv-trigger="blur"
                    data-bv-notempty="true"
                    data-bv-notempty-message="Shop address is required and cannot be empty"
                    ref={register({ required: true })}
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                  ></textarea>{" "}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="exampleInputUsername2"
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  โทรศัพท์
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.phone_number ? "error-input" : ""
                    }`}
                    name="phone_number"
                    placeholder="โทรศัพท์"
                    ref={register({ required: true })}
                    value={phone_number}
                    onChange={(e) => setphone_number(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="exampleInputUsername2"
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  อีเมล
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.email ? "error-input" : ""
                    }`}
                    name="email"
                    placeholder="อีเมลล์"
                    ref={register({ required: true })}
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="exampleInputUsername2"
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  เลขประจำตัวผู้เสียภาษี
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.tax_number ? "error-input" : ""
                    }`}
                    name="tax_number"
                    ref={register({ required: true })}
                    value={tax_number}
                    onChange={(e) => settax_number(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <button type="submit" className="btn btn-primary mr-2 ml-auto">
                  บันทึก
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { api, header_token } from "./config";
import Axios from "axios";
import swal from "sweetalert";

import "./App.css";
import { Admin } from "./Admin";

import { LoginForm } from "./LoginForm";

function App() {
  const [token, settoken] = useState(localStorage.getItem("token"));
  const [role] = useState(localStorage.getItem("role"));

  const checkToken = async () => {
    setTimeout(async () => {
      try {
        let { data } = await Axios.get(`${api}/branch/owner`, header_token);
        // console.log("check");

        if (data.status === 401) {
          // console.log("402");
          settoken(null);
          localStorage.removeItem("token");
          swal(`กรุณาล็อคอินใหม่อีกครั้ง`, {
            icon: "error",
            button: false,
          });
        }
      } catch (error) {
        settoken(null);
        swal(`กรุณาล็อคอินใหม่อีกครั้ง`, {
          icon: "error",
          button: false,
        });
        console.log(error);
      }
    }, 300);
  };

  useEffect(() => {
    // checkToken();
    // console.log("trigger !!!");
  }, []);

  return (
    <div>
      {(() => {
        if (!token) {
          return (
            <Switch>
              <Route exact path="/" component={LoginForm} />
              <Route path="*" component={LoginForm} />
            </Switch>
          );
        } else {
          if (role === "admin") {
            checkToken();
            return <Admin token={token} />;
          }
        }
      })()}
    </div>
  );
}

export default App;

import React, { useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import swal from "sweetalert";
import { header_token, api } from "../../config";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

export const SendingAssign = (props) => {
  const checkAllRef = useRef(null);

  const [riderloading, setriderloading] = useState(false);

  const [filter_order, setfilter_order] = useState("");
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);

  const [select_order, setselect_order] = useState("");
  const [select_rider, setselect_rider] = useState("");
  const [order_option, setorder_option] = useState([]);
  const [rider_option, setrider_option] = useState([]);

  const assignOrder = async (rider_id, order_id) => {
    try {
      let send_data = {
        order_id: order_id,
        rider_id: rider_id,
      };

      let res = await Axios.post(
        `${api}/branch/order/assign/`,
        send_data,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const cancelOrder = async (order_id) => {
    try {
      let res = await Axios.get(
        `${api}/branch/order/cancel/${order_id}`,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataOnlineRider = async () => {
    setriderloading(true);
    try {
      let send_data = {
        status: [1],
        // team_id: filter_team.value,
        role: "rider",
        available: "online",
      };

      let { data } = await Axios.post(
        `${api}/branch/user/filter?page=${1}&limit=${9999999}`,
        send_data,
        header_token
      );
      // console.log(data);
      let tmp = [];
      data.users.map((val) => {
        tmp.push({ value: val.id, label: `${val.user_id} ${val.full_name}` });
      });

      setrider_option(tmp);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setriderloading(false);
  };

  useEffect(() => {
    // console.log(props);
    if (props.data) {
      let tmp = [];
      props.data.map((val) => {
        tmp.push({ value: val.id, label: val.order_no });
      });
      setorder_option(tmp);
      setdata(props.data);

      let cal_page_count = Math.ceil(props.data.length / limit);
      setpage_count(cal_page_count);
    }
  }, [props]);

  return (
    <div>
      <div className="row">
        <div className="ml-auto">
          <div className="form-row align-items-center mb-2">
            <div className="col ml-1 mr-3">
              <div className="row">
                <div style={{ width: 150 }} className="">
                  <span className="tx-13 text-muted">ดำเนินการ</span>
                  <Select
                    value={filter_order}
                    onChange={async (e) => {
                      setfilter_order(e);
                    }}
                    options={[
                      { value: "assign", label: "มอบหมายงาน" },
                      { value: "cancel", label: "ยกเลิก" },
                    ]}
                    placeholder={"เลือก..."}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          data-toggle="modal"
          data-target={`${
            filter_order.value === "assign" ? ".assign-order" : ""
          }`}
          className="btn btn-primary mr-2 "
          style={{ height: 42, marginTop: 20 }}
          onClick={async () => {
            if (
              !filter_order.value ||
              data.filter((e) => e.checked === true).length === 0
            ) {
              alert("เลือกตัวดำเนินการ");
              return;
            }

            if (filter_order.value === "assign") {
              let tmp = [];
              data.forEach((value) => {
                if (value.checked === true) {
                  tmp.push({
                    value: value.id,
                    label: value.order_no,
                  });
                }
              });
              setselect_order(tmp);

              await getDataOnlineRider();
            } else if (filter_order.value === "cancel") {
              swal({
                title: `ต้องการยกเลิกออเดอร์ที่เลือกหรือไม่ ?`,
                confirmButtonText: "ใช่",
                cancelButtonText: "ไม่",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  let sending = [];
                  data.forEach((value) => {
                    if (value.checked === true) {
                      sending.push(cancelOrder(value.id));
                    }
                  });

                  let is_success = true;
                  await Promise.all(sending).then((res) => {
                    // console.log(res);
                    if (
                      res.filter((e) => e === true).length === 0 ||
                      res.length === 0
                    )
                      is_success = false;
                  });

                  if (is_success) {
                    swal("ยกเลิกเสร็จสิ้น !!", {
                      icon: "success",
                    });
                  } else {
                    swal("เกิดข้อผิดพลาด !!", {
                      icon: "error",
                    });
                  }
                }
              });
            }
          }}
        >
          ดำเนินการ
        </button>
      </div>

      <div className="table-responsive">
        <table id="dataTable" className=" table text-wrap" cellSpacing="0">
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>
                <input
                  type="checkbox"
                  className="selectAll "
                  name="selectAll"
                  defaultChecked={false}
                  ref={checkAllRef}
                  onClick={(e) => {
                    // console.log(checkAllRef.current.checked);
                    let tmp = [...data];
                    let _tmp = [];
                    tmp
                      .slice((current_page - 1) * limit, current_page * limit)
                      .forEach((value, index) => {
                        value.checked = checkAllRef.current.checked;
                        _tmp.push(value);
                      });

                    setdata(tmp);
                    // console.log(data);
                  }}
                />
              </th>
              <th style={{ minWidth: 60 }}>Order No.</th>
              <th style={{ minWidth: 120 }}>เวลา</th>
              <th style={{ minWidth: 300 }}>ร้านอาหาร</th>
              <th style={{ minWidth: 300 }}>สถานที่จัดส่ง</th>
              <th style={{ minWidth: 80 }}>ค่าอาหาร</th>
              <th style={{ minWidth: 80 }}>ค่าจัดส่ง</th>
              <th style={{ minWidth: 120 }}>สถานะ</th>
              <th style={{ minWidth: 150 }}>ดำเนินการ</th>
            </tr>
          </thead>
          <tbody>
            {data
              .slice((current_page - 1) * limit, current_page * limit)
              .map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={value.checked || ""}
                        onChange={(e) => {
                          let tmp = [...data];
                          data.forEach((vl, idx) => {
                            if (vl.id === value.id) {
                              tmp[idx].checked = !tmp[idx].checked;
                              setdata(tmp);
                            }
                          });
                        }}
                      />
                    </td>
                    <td>
                      <a
                        tabIndex={0}
                        onClick={() =>
                          props.pushToLink(`/order/detail/${value.id}`)
                        }
                      >
                        {value.order_no}
                      </a>{" "}
                    </td>
                    <td>
                      <i className="fa fa-clock-o mr-1"></i>{" "}
                      {moment(value.created_at).format("LT")}
                    </td>
                    <td style={{ textAlign: "left" }}>
                      <a
                        tabIndex={0}
                        onClick={() =>
                          props.pushToLink(
                            `/restaurant/detail/${value.restaurant.id}/history`
                          )
                        }
                      >
                        {value.restaurant.name_th}
                        <br />
                        {value.restaurant.phone_numbers}
                      </a>
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {value.contact_name}
                      <br />
                      {value.to.address}
                      {", "}
                      {`ตำบล${value.to.sub_district.name_th}`}
                      {", "}
                      {`อำเภอ${value.to.district.name_th}`}
                      {", "}
                      {`จังหวัด${value.to.province.name_th}`}
                      {", "}
                      {`${value.to.sub_district.zip_code}`}{" "}
                    </td>
                    <td>฿{value.food_cost}</td>
                    <td>฿{value.shipping_cost}</td>
                    <td>
                      {(() => {
                        if (value.type === 0) return "ออเดอร์ล่วงหน้า";

                        switch (value.status) {
                          case 0:
                            return "ยกเลิก";
                          case 1:
                            return "ออเดอร์ใหม่";
                          case 4:
                            return "กำลังจัดส่ง";
                          case 5:
                            return "ส่งสำเร็จ";
                          case 6:
                            return "ส่งไม่สำเร็จ";

                          default:
                            break;
                        }
                      })()}
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          props.pushToLink(`/order/detail/${value.id}`)
                        }
                        className="btn btn-outline-primary btn-sm"
                      >
                        รายละเอียด
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="mt-2" style={{ float: "right" }}>
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={<span className="gap">...</span>}
          pageCount={page_count}
          onPageChange={(e) => setcurrent_page(e.selected + 1)}
          forcePage={current_page - 1}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
        />
      </div>
      <br />
      <br />
      <div id="showModdal" className="modal  assign-order" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>มอบหมายงาน</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">คำสั่งซื้อ Order No.</label>
                </div>
                <div className="col-lg-8">
                  <Select
                    value={select_order}
                    onChange={async (e) => {
                      setselect_order(e);
                    }}
                    options={order_option}
                    placeholder={"เลือก..."}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">พนักงานขับ</label>
                </div>

                <div className="col-lg-8">
                  <LoadingMask loading={riderloading} text={"loading..."}>
                    <Select
                      value={select_rider}
                      onChange={async (e) => {
                        setselect_rider(e);
                      }}
                      options={rider_option}
                      placeholder={"เลือก..."}
                    />
                  </LoadingMask>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-11">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      onClick={async () => {
                        if (select_order && select_rider) {
                          let send = [];
                          select_order.forEach((value) => {
                            send.push(
                              assignOrder(select_rider.value, value.value)
                            );
                          });

                          let is_success = true;
                          await Promise.all(send).then((res) => {
                            if (
                              res.filter((e) => e === true).length === 0 ||
                              res.length === 0
                            )
                              is_success = false;
                          });

                          if (is_success) {
                            props.refresh();
                            setselect_order("");
                            setselect_rider("");
                            swal("หมอบหมายงานเสร็จสิ้น !!", {
                              icon: "success",
                            });
                          } else {
                            swal("เกิดข้อผิดพลาด !!", {
                              icon: "error",
                            });
                          }
                        }
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
